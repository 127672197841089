import { Fertilizer } from "@/store/modules"
import FertilizerAPI from "@/api/FertilizerAPI"

const state = {
  fertilizers: [],
}

const getters = {
  [Fertilizer.Getters.getFertilizers]: state => state.fertilizers,
}

const mutations = {
  [Fertilizer.Mutations.setFertilizers](state, ferts) {
    // adding for v-select dropdowns
    ferts.forEach(f => {
      f["display_name"] = f["fert_shorthand"]
      f["value"] = f["fert"]
    })
    state.fertilizers = ferts
  },
}

const actions = {
  [Fertilizer.Actions.fetchFertilizers]({ commit }) {
    return new Promise(resolve => {
      FertilizerAPI.listAllFertilizer().then(response => {
        // filters fertilizerprice table data by most recent date_pulled value
        // when region becomes important, this will need to change
        let result = Object.values(
          response.data.reduce((a, b) => {
            const b_key = b.fert_shorthand + b.fert_application
            if (a[b_key]) {
              if (a[b_key].date_pulled < b.date_pulled)
                a[b_key] = b
            } else a[b_key] = b

            return a
          }, {})
        )
        commit(Fertilizer.Mutations.setFertilizers, result)
        resolve()
      })
    })
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
