<template>
  <b-sidebar id="evidencing-table-sidebar" width="534px" :title="getTitle(action)" v-model="show" right shadow backdrop :class="show ? 'is-shown' : ''">
    <div v-if="loading['state'] == true">
      <div class="sidebar-loading-wrapper" :class="loading['action'] == LOADER_ERROR ? 'error-sizing' : ''">
        <v-progress-circular v-if="loading['action'] == LOADER_PROCESSING" :size="48" color="#79c61c"
          class="sidebar-progress" indeterminate />
        <v-icon v-else-if="loading['action'] == LOADER_SUCCESS" :size="48" color="#79c61c" dark>mdi-check-bold</v-icon>
        <v-icon v-else-if="loading['action'] == LOADER_ERROR" :size="48"
          color="#ff3c7e">mdi-alert-circle-outline</v-icon>

        <div class="sidebar-error-message" v-if="loading['action'] == LOADER_ERROR">
          <p class="text-danger">Something went wrong...</p>
          <p>The request could not be processed ({{ loadingErrorMsg }}).</p>
          <v-btn @click="resetLoadingState()">close</v-btn>
        </div>
      </div>

      <div class="progress-overlay" />
    </div>

    <div class="table-action-sidebar-body" v-if="action == UPLOAD_DATA_ACTION">
      <p>Add details to your upload for the 2024 evidencing on these fields.</p>

      <div class="evidencing-mini-table evidencing-list">
        <div class="evidencing-mini-table-heading">
          <p class="field-label">{{ evidencing.length }} Field{{ evidencing.length == 1 ? '' : 's' }}</p>
          <p class="type-label">TYPE</p>
        </div>

        <div class="evidencing-mini-table-scroller evidencing-list-scroller">
          <div class="evidencing-item" v-for="evidencing, idx in sortedEvidencingByField" :key="`${evidencing['id']}-${idx}`">
            <div class="item-prepend-icon">
              <img :src="getThumbnail(evidencing)" @error="(e) => setFallbackSrc(e)">
            </div>
            <p class="fields-row-label">{{ getFields(evidencing) }}</p>
            <p class="type-row-label">{{ getFormattedEvidencingType(evidencing['confirmation_type']) }}</p>
          </div>
        </div>
      </div>

      <div class="evidencing-mini-table">
        <div class="evidencing-mini-table-heading">
          <p class="singular-label">Evidence types for document to fulfill</p>
        </div>

        <div class="evidencing-mini-table-scroller">
          <div class="evidencing-item" v-for="evidencingType, idx in getUniqueEvidencingTypes" :key="`${evidencingType}-${idx}`">
            <div class="item-prepend-icon">
              <img :src="checkIcon">
            </div>
            <p>{{ getFormattedEvidencingType(evidencingType) }}</p>
            <!-- <div class="item-append-icon">
              <img :src="infoIcon">
            </div> -->
          </div>
        </div>
      </div>

      <div class="evidencing-mini-table">
        <div class="evidencing-mini-table-heading">
          <p class="singular-label">What document type are you adding?</p>
        </div>

        <div class="evidencing-mini-table-inputs">
          <v-radio-group v-model="docTypeSelection">
            <div
              v-for="docType, idx in documentUploadTypes"
              :key="`${docType['name']}-${idx}`"
              class="radio-wrapper"
            >
              <v-radio
                :label="docType['name']"
                :value="docType['value']"
                :input.icon="mapIcon"
              />
              <!-- <div class="item-append-icon">
                <img :src="infoIcon">
              </div> -->
            </div>
          </v-radio-group>

          <div v-if="docTypeSelection == 'other'">
            <p>Please specify the document type (required)</p>
            <v-textarea
              v-model="otherDocType"
              error
              rows="1"
              outlined
            />
          </div>
        </div>
      </div>

      <div class="evidencing-mini-table instructions-list">
        <div class="evidencing-mini-table-heading">
          <p class="singular-label">Instructions</p>
        </div>

        <div class="evidencing-mini-table-scroller instructions-list-scroller">
          <div class="instructions-item">
            <p>General</p>
            <ul>
              <li>Zip, PDF, JPG, TXT, Shapefiles, and CSV are all accepted formats.</li>
              <li>If your crop plan or other documents have multiple pages, they may be uploaded as a zip file. If you have many of the same documents for many fields, they may be uploaded as a zip file and applied to all relevant fields.</li>
              <li>The files you upload here serve a specific purpose. They will not automatically alter the data in your field in our system. They are currently used as evidence of practices, maintaining the integrity of your original data.</li>
            </ul>
          </div>
        </div>
      </div>

      <div>
        <p>Add notes</p>
        <v-textarea
          rows="6"
          placeholder="Notes"
          outlined
          v-model="notes"
        />
      </div>

      <div>
        <v-file-input
          v-model="files"
          label="Attach Files"
          multiple
          outlined
        />
      </div>

      <div class="evidencing-mini-table">
        <div class="evidencing-mini-table-heading">
          <p class="upload-label">Files</p>
          <p class="location-label">Fields</p>
          <p class="date-label">Date</p>
        </div>

        <div class="evidencing-mini-table-scroller files-scroller">
          <div
            class="files-item"
            v-for="uniqueUpload, idx in getAllUniqueFileUploads"
            :key="`${uniqueUpload['name']}-${idx}`"
          >
            <p class="filename without-delete-btn">{{ uniqueUpload['name'] }}</p>
            <p class="location">{{ uniqueUpload['relevantFields'].length || 1 }}</p>
            <p class="date">{{ new Date(uniqueUpload['created_at']).toLocaleDateString() }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="table-action-sidebar-body" v-else-if="action == ASSIGN_USERS_ACTION">
      <p>
        Please select a user or users to assign these evidencing requests to.
      </p>
      
      <div class="evidencing-mini-table evidencing-list">
        <div class="evidencing-mini-table-heading">
          <p class="field-label">{{ evidencing.length }} Field{{ evidencing.length == 1 ? '' : 's' }}</p>
          <p class="type-label">TYPE</p>
        </div>

        <div class="evidencing-mini-table-scroller evidencing-list-scroller">
          <div class="evidencing-item" v-for="evidencing, idx in sortedEvidencingByField" :key="`${evidencing['id']}-${idx}`">
            <div class="item-prepend-icon">
              <img :src="getThumbnail(evidencing)" @error="(e) => setFallbackSrc(e)">
            </div>
            <p class="fields-row-label">{{ getFields(evidencing) }}</p>
            <p class="type-row-label">{{ evidencing['confirmation_type'] }}</p>
          </div>
        </div>
      </div>

      <p>
        Once assigned, the selected users will then be able to upload evidencing
        via the web application or by the mobile evidencing application.
      </p>

      <div class="evidencing-assignment-zone">
        <div v-if="assignmentStep == 'initial'" class="assign-btn-group center-main-btns">
          <v-btn @click="assignmentStep = 'self-assign'" class="user-assign-btn" height="44px" outlined>Assign Myself</v-btn>
          <v-btn disabled @click="assignmentStep = 'cs-assign'" class="user-assign-btn" height="44px" outlined>Assign Customer Success</v-btn>
          <v-btn @click="assignmentStep = 'user-assign'" class="user-assign-btn" height="44px" outlined>Select Users</v-btn>
        </div>

        <div v-else-if="assignmentStep == 'self-assign'" class="assign-btn-group">
          <p class="full-width-p">Assign {{ evidencing.length }} evidencing request{{ evidencing.length == 1 ? '' : 's' }} to yourself? ({{ user['email'] }})</p>

          <v-btn height="44px" outlined @click="assignmentStep = 'initial'">Cancel</v-btn>
          <v-btn height="44px" outlined @click="assignEvidencing('self-assign')">Confirm</v-btn>
        </div>

        <div v-else-if="assignmentStep == 'cs-assign'" class="assign-btn-group">
          <p class="full-width-p">Assign {{ evidencing.length }} evidencing request{{ evidencing.length == 1 ? '' : 's' }} to the customer success team?</p>

          <v-btn height="44px" outlined @click="assignmentStep = 'initial'">Cancel</v-btn>
          <v-btn height="44px" outlined @click="assignEvidencing('cs-assign')">Confirm</v-btn>
        </div>

        <div v-else-if="assignmentStep == 'user-assign'" class="assign-btn-group">
          <p class="full-width-p">Select which users to assign to:</p>

          <v-autocomplete
            v-model="userSelections"
            chips
            multiple
            clearable
            label="Search for users (autocompletes)"
            :items="userAssignableEmails"
            item-text="name"
            item-value="id"
          />

          <v-btn height="44px" outlined @click="assignmentStep = 'initial'">Cancel</v-btn>
          <v-btn height="44px" outlined @click="assignmentStep = 'confirm-user-assign'">Assign</v-btn>
        </div>

        <div v-else-if="assignmentStep == 'confirm-user-assign'" class="assign-btn-group">
          <p>Assign {{ evidencing.length }} evidencing request{{ evidencing.length == 1 ? '' : 's' }} to these users?</p>

          <div class="user-assignment-list">
            <p v-for="userId, idx in userSelections" :key="`${userId}-${idx}`">{{ userAssignableEmails.find(({ id }) => id == userId)['name'] }}</p>
          </div>

          <v-btn height="44px" outlined @click="assignmentStep = 'initial'; userSelections=[]">Cancel</v-btn>
          <v-btn height="44px" outlined @click="assignEvidencing('user-assign')">Confirm</v-btn>
        </div>
      </div>
    </div>

    <template v-if="action == UPLOAD_DATA_ACTION" #footer="{ hide }">
      <div class="footer-errors-and-btns">
        <v-btn :disabled="!isSavingEnabled" outlined height="44px" @click="handleSave">Save</v-btn>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import {
  LOADER_PROCESSING,
  LOADER_SUCCESS,
  LOADER_ERROR
} from "@/constants/arvaNotifications";
import { Uploads } from "@/store/modules";
import { mapState, mapActions } from "vuex";
import { assignUsersToEvidencing } from "@/api/NotificationsAPI";
import { UPLOAD_DATA_ACTION, ASSIGN_USERS_ACTION, EXPORT_TABLE_ACTION } from "@/constants";
import { EVIDENCING_TYPE_CHOICES } from "@/constants/defaults";
import { modifyEvidencingNotes } from "@/api/EvidencingAPI";
import mapIcon from "@/assets/images/map-icon.svg";
import checkIcon from "@/assets/images/check-icon.svg";
import infoIcon from "@/assets/images/info-icon.svg";
import xIcon from "@/assets/images/x-icon.svg";
import UserAPI from "@/api/UserAPI";
import moment from "moment";
import JSZip from "jszip";


export default {
  name: "EvidencingActionSidebar",
  props: {
    action: { required: true },
    evidencing: { required: true },
    visible: { required: true }
  },
  emits: ["closeSidebar"],
  data() {
    return {
      show: false,
      files: [],
      assignmentStep: 'initial',
      userSelections: [],
      userAssignableEmails: [],
      docTypeSelection: '',
      otherDocType: '',
      notes: '',
      notesAreModified: false,
      tooltippedUpload: {},
      loading: {
        state: false,
        action: LOADER_PROCESSING
      },
      documentUploadTypes: [
        { name: "Crop Plan", value: "crop_plan_upload" },
        { name: "Nutrient Plan", value: "nutrient_plan_upload" },
        { name: "Crop & Nutrient Plan", value: "crop_and_nutrient_plan" },
        { name: "Receipt / Purchase Order", value: "work_order_upload" },
        { name: "On Location Image", value: "image_capture" },
        { name: "Insurance Documentation", value: "insurance_documentation" },
        { name: "Attestation Documentation", value: "manual_attestation" },
        { name: "Other", value: "other" },
      ],
      loadingErrorMsg: '',
      LOADER_ERROR,
      LOADER_PROCESSING,
      LOADER_SUCCESS,
      mapIcon,
      checkIcon,
      infoIcon,
      xIcon,
      UPLOAD_DATA_ACTION,
      ASSIGN_USERS_ACTION,
      EXPORT_TABLE_ACTION
    }
  },
  methods: {
    ...mapActions({
      createUpload: Uploads.Actions.create,
    }),
    getFormattedEvidencingType(evidencingType) {
      const foundFormatted = EVIDENCING_TYPE_CHOICES.find(({ value }) => value == evidencingType);
      if (foundFormatted != null) {
        return foundFormatted['name'];
      }

      return evidencingType
    },
    getThumbnail(evidencing) {
      if (evidencing['fields'] != null && evidencing['fields'].length > 0) {
        return evidencing['fields'][0]['boundary_image']
      }

      return mapIcon
    },
    setFallbackSrc(e) {
      e.target.src = mapIcon;
    },
    getTitle(action) {
      if (action == UPLOAD_DATA_ACTION) {
        return `Add Evidencing for ${this.evidencing.length} Request${this.evidencing.length == 1 ? '' : 's'}`;
      }
    },
    assignEvidencing(target) {
      this.loading = { state: true, action: LOADER_PROCESSING };

			// wait 5 seconds to perform the action, if it hasn't worked by then call it
			// a server timeout error
			new Promise((resolve) => setTimeout(() => resolve(), 5000))
			.then(() => {
				if (this.loading['state'] && this.loading['action'] == LOADER_PROCESSING) {
					// we were still loading after the timeout, show a timeout error message
					// and get out of loading state
					this.loading = { state: true, action: LOADER_ERROR }
					this.loadingErrorMsg = "Server Error: timeout"
				}
			});

			const user_ids = [];
      const confirmation_ids = this.evidencing.map(({ id }) => id);

			if (target == 'self-assign') user_ids.push(this.user['id']);
			if (target == 'cs-assign') user_ids.push(this.user['id']);
			if (target == 'user-assign') user_ids.push(...this.userSelections);

			assignUsersToEvidencing({ confirmation_ids, user_ids })
			.then(response => {
				if (response) {
					this.loading['action'] = LOADER_SUCCESS;
					new Promise((resolve) => setTimeout(() => resolve(), 500))
					.then(() => {
						this.removeSidebar(true);
					});
				};
			})
			.catch(err => {
				this.loading['action'] = LOADER_ERROR;
				this.loadingErrorMsg = `Server Error: ${err}`
			});
    },
    getFields(evidencing) {
      if (evidencing['fields'] != null && evidencing['fields'].length > 0) {
        return evidencing['fields'].map(({ name }) => name).join(', ');
      }

      return 'Not Specified';
    },
    removeSidebar() {
      this.files = [];
      this.notes = '';
      this.notesAreModified = false;
      this.docTypeSelection = '';
      this.otherDocType = '';
      this.assignmentStep = 'initial';
      this.userSelections = []
      this.resetLoadingState();
      this.$emit('closeSidebar');
    },
    resetLoadingState() {
      this.loading['state'] = false;
      this.loading['action'] = LOADER_PROCESSING;
      this.loadingErrorMsg = '';
    },
    async handleSave() {
      this.resetLoadingState();
      this.loading = { state: true, action: LOADER_PROCESSING };

      let notesFinished = false;
      let uploadsFinished = false;

      // wait 5 seconds to perform the action, if it hasn't worked by then call it
      // a server timeout error
      new Promise((resolve) => setTimeout(() => resolve(), 5000))
        .then(() => {
          if (this.loading['state'] && this.loading['action'] == LOADER_PROCESSING) {
            // we were still loading after the timeout, show a timeout error message
            // and get out of loading state
            this.loading = { state: true, action: LOADER_ERROR }
            this.loadingErrorMsg = "Server Error: timeout"
          }
        });

      const evidencingNumbers = this.evidencing.map(({ confirmation_number }) => confirmation_number);

      if (this.notesAreModified) {
				modifyEvidencingNotes({
					evidencingNumbers: evidencingNumbers,
          bulkNotesUpdate: evidencingNumbers.length > 1,
					notes: this.notes
				})
        .then(_ => {
            notesFinished = true;
            this.loading['action'] = LOADER_SUCCESS;
            new Promise((resolve) => setTimeout(() => resolve(), 500))
              .then(() => {
                if (uploadsFinished) this.removeSidebar(true);
              });
          })
          .catch(err => {
            notesFinished = true;
            this.loading['action'] = LOADER_ERROR;
            this.loadingErrorMsg = `Server Error: ${err}`
          });
      }
      else {
        notesFinished = true;
        if (uploadsFinished) {
          this.loading['action'] = LOADER_SUCCESS;
          new Promise((resolve) => setTimeout(() => resolve(), 500))
          .then(() => {
            this.removeSidebar(true);
          });
        }
      }

      if (this.files.length > 0) {
        const uploadSpec = {
          orgId: this.organization.id,
          datasetType: 'practice-confirmations',
          specs: {
            confirmationNumbers: evidencingNumbers,
            createNewConfirmation: false,
            usedOtherConfirmationSourceOption: this.docTypeSelection == 'other',
            confirmationSource: this.docTypeSelection == 'other' ? this.otherDocType : this.docTypeSelection
          }
        }

        const zip = new JSZip();
        for (const file of this.files) zip.file(file.name, await file.arrayBuffer());
        const result = await zip.generateAsync({ type: "blob" });
        const datetimeStr = moment().format("YYYY-MM-DD_h:mm:ss");
        const orgName = this.organization.name;
        const newZipName = `${orgName}-practice-confirmations-${datetimeStr}.zip`;
        uploadSpec['file'] = new File([result], newZipName);

        this.createUpload(uploadSpec)
          .then(_ => {
            uploadsFinished = true;
            this.loading['action'] = LOADER_SUCCESS;
            new Promise((resolve) => setTimeout(() => resolve(), 500))
              .then(() => {
                if (notesFinished) this.removeSidebar(true);
              });
          })
          .catch(err => {
            uploadsFinished = true;
            this.loading['action'] = LOADER_ERROR;
            this.loadingErrorMsg = `Server Error: ${err}`
          });
      }
      else {
        uploadsFinished = true;
        if (notesFinished) {
          this.loading['action'] = LOADER_SUCCESS;
          new Promise((resolve) => setTimeout(() => resolve(), 500))
          .then(() => {
            this.removeSidebar(true);
          });
        }
      }
    },
  },
  computed: {
    ...mapState({
      organization: state => state.Organization.organization,
      user: state => state.User.user
    }),
    getAllUniqueFileUploads() {
      const uniqueUploads = [];

      for (const { uploads, fields } of this.evidencing) {
        for (const upload of uploads) {
          const uploadIdx = uniqueUploads.findIndex(({ id }) => id == upload['id']);
          if (uploadIdx == -1) {
            const newUploadWithContext = { ...upload };
            newUploadWithContext['relevantFields'] = fields
            uniqueUploads.push(newUploadWithContext);
          }
          else {
            if (fields != null && fields.length > 0) {
              for (const field of fields) {
                const foundExistingFieldInContext = uniqueUploads[uploadIdx]['relevantFields'].find(({ id }) => id == field['id']);
                if (foundExistingFieldInContext == null) {
                  uniqueUploads[uploadIdx]['relevantFields'].push(field);
                }
              }
            }
          }
        }
      }

      return uniqueUploads
    },
    isSavingEnabled() {
      if (this.action == UPLOAD_DATA_ACTION) {
        if (this.files.length > 0) {
          if (this.docTypeSelection == 'other' && this.otherDocType.length != null && this.otherDocType.length > 0) {
            return true
          }
          else {
            return true
          }
        }

        if (this.notes != null && this.notes.length > 0) {
          if (this.evidencing.length == 1) {
            if (this.notes != this.evidencing[0]['notes']) {
              return true
            }
          }
          else {
            return true
          }
        }
      }
    },
    sortedEvidencingByField() {
      return this.evidencing.toSorted((a, b) => {
        if (a['fields'] != null && a['fields'].length > 0 && b['fields'] != null && b['fields'].length > 0) {
          return a['fields'][0]['name'].localeCompare(b['fields'][0]['name']);
        }

        return 0
      })
    },
    getUniqueEvidencingTypes() {
      const evidencingTypes = [];

      for (const { confirmation_type } of this.evidencing) {
        if (!evidencingTypes.includes(confirmation_type)) {
          evidencingTypes.push(confirmation_type)
        }
      }

      return evidencingTypes.toSorted();
    }
  },
  watch: {
    visible(curr) {
      this.show = curr;
    },
    evidencing(curr) {
      if (curr && curr.length == 1) {
        this.notes = curr[0]['notes'];
        
        if (this.documentUploadTypes.find(({ value }) => curr[0]['confirmation_source'] == value) != null) {
          this.docTypeSelection = curr[0]['confirmation_source'];

          if (curr[0]['confirmation_source'] == 'other') {
            this.otherDocType = curr[0]['other_source_manual_specification'];
          }
        }
      }
      else {
        this.notes = '';
      }
    },
    notes(curr) {
      this.notesAreModified = false;

      if (this.evidencing.length == 1) {
        if (curr != this.evidencing[0]['notes']) this.notesAreModified = true;
      }
      else {
        if (curr != null && curr.length > 0) this.notesAreModified = true;
      }
    },
    show(curr) {
      if (!curr) {
        this.removeSidebar()
        return
      }
      
      if (this.userAssignableEmails.length == 0) {
        UserAPI.fetchAllUserContacts(this.user['id'])
        .then(response => {
          this.userAssignableEmails = response['data']['emails'].map(({ id, name, email }) => {
            let nameStr = '';

            if (name != null){
              nameStr += `${name} (${email})`;
            }
            else {
              nameStr = email;
            }

            return {
              id,
              name: nameStr
            }
          })
          .toSorted((a, b) => a['name'].localeCompare(b['name']));
        });
      }
    }
  }
}
</script>

<style lang="css" scoped>
::v-deep(#evidencing-table-sidebar) {
  background: #FFFFFF !important;
}

p {
  color: #000000;
}

.sidebar-loading-wrapper {
  position: absolute;
  top: calc(50% - 48px);
  left: calc(50% - 48px);
  width: 96px;
  background-color: white;
  padding: 24px;
  z-index: 10;
  border-radius: 8px;
}

.sidebar-loading-wrapper.error-sizing {
  width: 250px;
  text-align: center;
  left: calc(50% - 125px);
}

.progress-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.15);
  z-index: 9;
}

.table-action-sidebar-body {
  padding: 0 36px 24px;
}
.table-action-sidebar-body > p {
  font-size: 16px;
  line-height: 24px;
  margin: 0;
}

::v-deep #evidencing-table-sidebar header {
  padding: 24px 36px;
}

::v-deep #evidencing-table-sidebar header .close {
  margin-right: 16px !important;
}

::v-deep #evidencing-table-sidebar header strong {
  margin-right: auto;
}

.evidencing-mini-table {
  display: flex;
  flex-wrap: wrap;
  background: #F7F8FA;
  border: 1px solid rgba(0, 0, 0, 0.10);
  border-radius: 8px;
  margin: 24px 0;
  position: relative;
  overflow: hidden;
}
.evidencing-mini-table.evidencing-list {
  max-height: 289px;
}
.evidencing-mini-table.instructions-list {
  background: #FFFFFF;
}
.evidencing-mini-table.instructions-list .evidencing-mini-table-heading {
  background: #FFFFFF;
}
.evidencing-mini-table .evidencing-mini-table-heading {
  position: sticky;
  top: 0;
  display: flex;
  width: 100%;
  background: #F7F8FA;
  border-bottom: 1px solid rgba(0, 0, 0, 0.10);
  margin: 0;
  padding: 12px 24px;
}
.evidencing-mini-table .evidencing-mini-table-heading .field-label,
.evidencing-mini-table .evidencing-mini-table-heading .type-label,
.evidencing-mini-table .evidencing-mini-table-heading .singular-label,
.evidencing-mini-table .evidencing-mini-table-heading .location-label,
.evidencing-mini-table .evidencing-mini-table-heading .date-label,
.evidencing-mini-table .evidencing-mini-table-heading .upload-label {
  font-size: 14px;
  line-height: 22px;
  color: #4e4041;
  margin-bottom: 0;
  font-weight: bold;
}
.evidencing-mini-table .evidencing-mini-table-heading .field-label {
  width: 228px;
  padding-right: 12px;
}
.evidencing-mini-table .evidencing-mini-table-heading .type-label {
  margin: 0 24px 0 auto;
  width: 160px;
}
.evidencing-mini-table .evidencing-mini-table-heading .singular-label {
  width: 100%;
  margin: 0;
}
.evidencing-mini-table .evidencing-mini-table-heading .upload-label {
  width: 157px;
  padding-right: 12px;
}
.evidencing-mini-table .evidencing-mini-table-heading .location-label {
  width: 110px;
  padding-right: 12px;
}
.evidencing-mini-table .evidencing-mini-table-heading .date-label {
  width: 110px;
}
.evidencing-mini-table-scroller {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-top: 16px;
  padding-bottom: 24px;
  width: 100%;
}
.evidencing-mini-table-scroller.evidencing-list-scroller,
.evidencing-mini-table-scroller.instructions-list-scroller,
.evidencing-mini-table-scroller.files-scroller {
  max-height: 242px;
}
.evidencing-mini-table p {
  font-size: 14px;
  line-height: 24px;
}
.evidencing-mini-table ::v-deep(label) {
  font-size: 14px;
  line-height: 1;
}
.evidencing-mini-table .evidencing-item {
  width: 100%;
  display: flex;
  margin: 0 24px 10px;
  align-items: center;
}
.evidencing-mini-table .evidencing-item:last-of-type,
.evidencing-mini-table .files-item:last-of-type {
  margin-bottom: 0;
}
.evidencing-mini-table .evidencing-item .item-prepend-icon,
.evidencing-mini-table .evidencing-item .item-append-icon,
::v-deep(.item-append-icon) {
  width: 20px;
  height: 20px;
}
.evidencing-mini-table .evidencing-item .item-prepend-icon img,
.evidencing-mini-table .evidencing-item .item-append-icon img,
::v-deep(.item-append-icon img) {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.evidencing-mini-table .evidencing-item .item-prepend-icon {
  margin-right: 12px;
}
.evidencing-mini-table .evidencing-item .item-append-icon {
  position: relative;
  margin-left: auto;
}
.evidencing-mini-table .evidencing-item .item-append-icon img,
::v-deep(.item-append-icon img) {
  opacity: 0.5;
}
.evidencing-mini-table .evidencing-item > p,
.evidencing-mini-table .instructions-item > p,
.evidencing-mini-table .files-item > p {
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.evidencing-mini-table .evidencing-item .fields-row-label {
  width: 196px;
  padding-right: 12px;
}
.evidencing-mini-table .evidencing-item .type-row-label {
  width: 185px;
  padding-right: 12px;
}
.evidencing-mini-table .instructions-item {
  padding: 0 48px 0 24px;
}
.evidencing-mini-table .files-item {
  display: flex;
  align-items: center;
  padding: 0 24px;
  margin-bottom: 10px;
}
.evidencing-mini-table .files-item .delete-btn {
  margin-right: 12px;
  opacity: 1;
  cursor: pointer;
}
.evidencing-mini-table .files-item .delete-btn:hover {
  opacity: 0.5;
}
.evidencing-mini-table .files-item .filename {
  width: 148px;
  padding-right: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.evidencing-mini-table .files-item .filename.without-delete-btn {
  width: 157px;
  padding-right: 12px;
}
.evidencing-mini-table .files-item .location {
  width: 110px;
  padding-right: 12px;
}
.evidencing-mini-table .files-item .date {
  width: 79px;
}
.evidencing-mini-table-inputs {
  width: 100%;
  padding: 0 24px;
}
.evidencing-mini-table-inputs .radio-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
}
button:deep(.v-ripple__container) {
  display: none !important;
}

.assign-btn-group {
	display: flex;
	flex-wrap: wrap;
  justify-content: flex-end;
  padding: 24px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}
.assign-btn-group > p {
  width: 100%;
}
.v-autocomplete {
  width: 100%;
}
.assign-btn-group > button:not(:last-of-type) {
  margin-right: 16px;
}
.center-main-btns {
  justify-content: center;
}
.center-main-btns > button {
  width: 65%;
  margin: 0 0 12px 0 !important;
}
.center-main-btns > button:last-of-type {
  margin-bottom: 0 !important;
}
.user-assignment-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 24px;
}
.user-assignment-list p {
  width: 100%;
  margin: 0;
}
::v-deep(.v-input--radio-group--column .v-messages) {
  display: none;
}
::v-deep(.v-radio) {
  margin-bottom: 0 !important;
}
::v-deep(.v-radio label) {
  color: #111827;
  margin: 0;
}
::v-deep(.v-radio .v-input--selection-controls__ripple) {
  display: none;
}
::v-deep(.b-sidebar-footer) {
  padding: 24px 84px 24px 36px;
  width: 100%;
  background: #FFFFFF;
  display: flex;
  justify-content: flex-end;
}
::v-deep(.b-sidebar-footer button) {
  margin-left: 10px;
}
::v-deep(.b-sidebar-footer .footer-errors-and-btns) {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
}
.evidencing-assignment-zone {
  margin-top: 24px;
}
.disabled-section {
  opacity: 0.5;
}
.not-allowed-cursor,
.not-allowed-cursor *,
.not-allowed-cursor ::v-deep(*) {
  cursor: not-allowed;
}
.not-allowed-cursor *,
.not-allowed-cursor ::v-deep(*) {
  pointer-events: none;
}
</style>
