<template>
  <b-tr>
    <!-- <b-th v-if="rowSpan > 0" scope="row" :rowspan="rowSpan">
      <img :src="field.fieldImg" style="width: 100px" />
    </b-th> -->
    <b-td sticky-column class="cell-table crop-column">
      <b-table-simple
        :class="`mb-0 ${
          isCropscapeUnavailablePlanting
            ? 'cropscape-unavailable-src'
            : 'light-blue-bg'
        }`"
      >
        <b-tr v-for="(n, index) in numberOfRows" :key="index">
          <b-td class="cell-table year-cell">
            <div v-if="n == 1">
              {{ fieldYear }}
            </div>
          </b-td>
          <b-td class="cell-table crop-cell">
            <div class="select-cropscape-confirm-wrapper">
              <v-select
                v-if="n == 1"
                dense
                hide-details="true"
                :items="filteredCropChoices"
                :readonly="field.year_data_locked"
                item-text="display_name"
                item-value="value"
                v-model="cultivationCrop"
              />
              <v-btn
                v-if="n === 1 && isCropscapeUnavailablePlanting"
                icon
                small
                fab
                class="action-button"
                @click="handleConfirmCropscapeUnavailableSource"
              >
                <v-tooltip top max-width="300">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" size="20"
                      >mdi-check-circle-outline</v-icon
                    >
                  </template>
                  <span>
                    Click to confirm our estimated crop type based on last
                    year's cropscape data. Otherwise, select a different crop
                    from the dropdown.
                  </span>
                </v-tooltip>
              </v-btn>
            </div>
          </b-td>
          <b-td v-if="!isReadOnly" class="cell-table actions-cell">
            <div class="icons-container">
              <v-btn
                v-if="n === 1"
                v-model="field.year_data_locked"
                :color="field.year_data_locked ? 'red' : 'green'"
                :disabled="spreadsheetNeedsSave || !isSuperUser"
                icon
                small
                fab
                class="action-button"
                @click="handleLockYearData"
              >
                <v-tooltip top max-width="300"
                  ><template v-slot:activator="{ on, attrs }"
                    ><v-icon v-bind="attrs" v-on="on" size="20">
                      {{
                        field.year_data_locked ? "mdi-lock" : "mdi-lock-open"
                      }}</v-icon
                    > </template
                  ><span
                    >The Lock feature allows users to lock this year data and
                    prevent the system from overriding data with other
                    sources.</span
                  ></v-tooltip
                >
              </v-btn>

              <v-btn
                v-if="
                  n === 1 && totalCropByYear != 1 && (isSuperUser || !isLocked)
                "
                color="red"
                icon
                small
                fab
                class="action-button"
                @click="handleDeleteYearData"
              >
                <v-icon size="20">mdi-delete</v-icon>
              </v-btn>

              <v-tooltip
                v-else-if="
                  n === 1 && totalCropByYear === 1 && (isSuperUser || !isLocked)
                "
                top
                max-width="300"
                ><template v-slot:activator="{ on, attrs }"
                  ><v-icon v-bind="attrs" v-on="on" size="20" color="grey"
                    >mdi-delete</v-icon
                  ></template
                ><span
                  >To ensure that each year has at least one assigned crop, if
                  no crops are planted, please select "Fallow" as the Crop
                  option for this year.</span
                ></v-tooltip
              >
            </div>
          </b-td>
        </b-tr>
      </b-table-simple>
    </b-td>
    <!-- planting -->
    <PlantingTable
      v-if="showPlanting"
      :cropId="field.arva_crop_id"
      :fieldId="newFieldSetting"
      :isLocked="isLocked"
      :plantingData="newPlantingData"
      :rowNum="rowNum"
      :year="fieldYear"
      :years="years"
      @bad-input="handleBadInput"
    />
    <!-- harvest -->
    <HarvestTable
      v-if="showHarvest"
      :cropId="field.arva_crop_id"
      :fieldId="newFieldSetting"
      :isLocked="isLocked"
      :harvestData="newHarvestData"
      :rowNum="rowNum"
      :year="fieldYear"
      :years="years"
      @bad-input="handleBadInput"
    />
    <!-- tillage -->
    <TillageTable
      v-if="showTillage"
      :cropId="field.arva_crop_id"
      :fieldId="newFieldSetting"
      :isLocked="isLocked"
      :tillageData="newTillageForYear"
      :rowNum="rowNum"
      :year="fieldYear"
      :years="years"
      @bad-input="handleBadInput"
    />
    <!-- fertilizer -->
    <FertilizerTable
      v-if="showFertilizer"
      :cropId="field.arva_crop_id"
      :fieldId="newFieldSetting"
      :isLocked="isLocked"
      :fertilizerData="newFertilizerData"
      :rowNum="rowNum"
      :year="fieldYear"
      :years="years"
      @bad-input="handleBadInput"
    />
    <!-- liming -->
    <LimingTable
      v-if="showLiming"
      :cropId="field.arva_crop_id"
      :fieldId="newFieldSetting"
      :isLocked="isLocked"
      :limingData="newLimingForYear"
      :rowNum="rowNum"
      :year="fieldYear"
      :years="years"
      @bad-input="handleBadInput"
    />
    <!-- crop protection -->
    <CropProtectionTable
      v-if="showCropProtection"
      :cropId="field.arva_crop_id"
      :fieldId="newFieldSetting"
      :isLocked="isLocked"
      :cropProtectionData="cropProtectionForYear"
      :rowNum="rowNum"
      :year="fieldYear"
      :years="years"
      @bad-input="handleBadInput"
    />
    <!-- irrigation -->
    <IrrigationTable
      v-if="showIrrigation"
      :cropId="field.arva_crop_id"
      :fieldId="newFieldSetting"
      :isLocked="isLocked"
      :irrigationData="newIrrigationForYear"
      :rowNum="rowNum"
      :year="fieldYear"
      :years="years"
      @bad-input="handleBadInput"
    />
    <!-- grazing -->
    <GrazingTable
      v-if="showGrazing"
      :cropId="field.arva_crop_id"
      :fieldId="newFieldSetting"
      :isLocked="isLocked"
      :grazingData="newGrazingForYear"
      :rowNum="rowNum"
      :year="fieldYear"
      :years="years"
      @bad-input="handleBadInput"
    />
    <!-- cover cropping -->
    <CoverCropTable
      v-if="showCoverCrop"
      :cropId="field.arva_crop_id"
      :fieldId="newFieldSetting"
      :isLocked="isLocked"
      :coverCropData="newCoverCropForYear"
      :rowNum="rowNum"
      :year="fieldYear"
      :years="years"
    />
    <!-- soil sampling -->
    <SoilSampleTable
      v-if="showSampling"
      :cropId="field.arva_crop_id"
      :fieldId="newFieldSetting"
      :isLocked="isLocked"
      :soilSampleData="soilSamplingForYear"
      :rowNum="rowNum"
      :year="fieldYear"
      :years="years"
    />
  </b-tr>
</template>
<script>
import { mapMutations, mapState } from "vuex"

import { CROP_KEY, CROP_DROPDOWN_OPTIONS } from "@/constants"
import { Defaults } from "@/store/modules"
import FieldsAPI from "@/api/FieldsAPI"
import DefaultsAPI from "@/api/DefaultsAPI"

import GrazingTable from "@/components/spreadsheet/GrazingTable"
import LimingTable from "@/components/spreadsheet/LimingTable"
import TillageTable from "@/components/spreadsheet/TillageTable"
import PlantingTable from "@/components/spreadsheet/PlantingTable"
import FertilizerTable from "@/components/spreadsheet/FertilizerTable"
import HarvestTable from "@/components/spreadsheet/HarvestTable"
import IrrigationTable from "@/components/spreadsheet/IrrigationTable"
import CoverCropTable from "@/components/spreadsheet/CoverCropTable"
import CropProtectionTable from "@/components/spreadsheet/CropProtectionTable"
import SoilSampleTable from "@/components/spreadsheet/SoilSampleTable"

export default {
  name: "SpreadsheetRow",
  components: {
    GrazingTable,
    LimingTable,
    TillageTable,
    PlantingTable,
    FertilizerTable,
    HarvestTable,
    IrrigationTable,
    CoverCropTable,
    CropProtectionTable,
    SoilSampleTable,
  },

  props: {
    allFields: { type: Array },
    field: { type: Object },
    rowNum: { type: Number },
    rowSpan: { type: Number },
    selectedFields: { type: Array },
    years: { type: Array },
    showPlanting: { type: Boolean },
    showTillage: { type: Boolean },
    showFertilizer: { type: Boolean },
    showHarvest: { type: Boolean },
    showLiming: { type: Boolean },
    showGrazing: { type: Boolean },
    showIrrigation: { type: Boolean },
    showCoverCrop: { type: Boolean },
    showCropProtection: { type: Boolean },
    showSampling: { type: Boolean },
    totalCropByYear: { type: Number },
  },

  data() {
    return {
      cropChoices: CROP_DROPDOWN_OPTIONS,
    }
  },

  computed: {
    ...mapState({
      isReadOnly: state => state.User.user.permission === "Read-Only",
      spreadsheetNeedsSave: state => state.Defaults.spreadsheetNeedsSave,
      isSuperUser: state => state.User.user.is_superuser,
    }),

    isLocked() {
      return this.field.year_data_locked //could optionally make this false if user is superuser
    },

    filteredCropChoices() {
      const excludeCrop = this.field.allCrops.filter(
        crop => crop != this.field.arva_crop_id
      )
      if (excludeCrop.length > 0) {
        return this.cropChoices.filter(o => o.value != excludeCrop[0])
      }
      return this.cropChoices
    },
    cultivationCrop: {
      get() {
        return this.field.arva_crop_id
      },
      async set(val) {
        const updatePayload = {
          field_id: this.field.fieldId,
          year: this.field.year,
          previous_crop: CROP_KEY[this.field.arva_crop_id],
          new_crop: CROP_KEY[val],
        }
        this.field.arva_crop_id = val
        await DefaultsAPI.updateFieldCropByFieldYear(updatePayload)
          .then(() => {
            this.$emit("refresh-overwrite")
          })
          .catch(error => console.log(error))
      },
    },
    isCropscapeUnavailablePlanting() {
      if (this.field == null) return false

      try {
        if (
          this.field["plantings"][0]["crop_id"]["source"] ==
          "cropscape_unavailable"
        ) {
          return true
        }
      } catch (e) {}

      return false
    },

    // coverCropForYear: {
    //   get() {
    //     return this.field.operations.cover_crop.cover_crop_exists.value
    //   },
    //   set(val) {
    //     this.field.operations.cover_crop.cover_crop_exists.value = val
    //     this.updateNewFieldSetting({
    //       fieldId: this.field.fieldId,
    //       cropId: this.field.arva_crop_id,
    //       dataCategory: "cover_crop",
    //       dataSetting: "cover_crop_exists",
    //       rowIndex: null,
    //       value: val,
    //       year: this.field.year,
    //     })
    //   },
    // },

    fieldSetting() {
      return this.field.fieldSetting
    },

    // NEW PREGENERATED-FORMAT
    newFieldSetting() {
      return this.field.fieldId
    },

    // fieldSettingsTemplate() {
    //   return this.tempField.field_settings
    //     ? this.fieldSettings[this.tempField.field_settings.id]
    //     : null
    // },

    fieldYear() {
      return this.field.year
    },

    // OPERATIONAL DATA (DRAINAGE, GRAZING, LIMING, TILLAGE)
    // NEW PREGENERATED-FORMAT
    newOperationData() {
      return this.field.operations
    },

    newPlantingData() {
      return this.field.plantings
    },

    newFertilizerData() {
      return this.field.fert_applications
    },

    newHarvestData() {
      return this.field.harvests
    },

    newDrainageForYear: {
      get() {
        return this.newOperationData.drainage.drainage_exists.value
      },
      set(val) {
        this.newOperationData.drainage.drainage_exists.value = val
      },
    },

    newGrazingForYear() {
      return this.newOperationData.grazings
    },

    newLimingForYear() {
      return this.newOperationData.limings
    },

    newTillageForYear() {
      return this.newOperationData.tillages
    },

    newIrrigationForYear() {
      return this.newOperationData.irrigations
    },

    newCoverCropForYear() {
      return this.newOperationData.cover_crop
    },

    cropProtectionForYear() {
      return this.field.crop_protection
    },

    soilSamplingForYear() {
      return this.field.soil_sampling
    },

    numberOfRows() {
      if (!this.newFertilizerData || this.newFertilizerData.length == 0) {
        return 1
      }
      return this.newFertilizerData.length
    },
  },

  methods: {
    ...mapMutations({
      updateNewFieldSetting: Defaults.Mutations.updateNewFieldSetting,
    }),
    async handleConfirmCropscapeUnavailableSource() {
      await FieldsAPI.confirmCropPlantingSource({
        field_id: this.field.fieldId,
        year: this.field.year,
        crop_id: this.field.arva_crop_id,
      })
        .then(() => {
          this.$emit("refresh-overwrite")
        })
        .catch(error => console.log(error))
    },
    handleBadInput(errorText) {
      this.$emit("bad-input", errorText)
    },
    handleDeleteYearData() {
      const payload = {
        field_id: this.field.fieldId,
        year: this.field.year,
        crop_id: this.cultivationCrop,
      }
      this.$emit("delete-year-data", payload)
    },
    handleLockYearData() {
      const payload = {
        field_id: this.field.fieldId,
        year: this.field.year,
        crop_id: this.cultivationCrop,
        year_data_locked: !this.field.year_data_locked,
      }
      this.$emit("lock-year-data", payload)
    },
  },
  watch: {
    field: {
      handler() {
        this.$emit("display-save")
      },
      deep: true,
    },
  },
}
</script>
<style scoped>
h2 {
  font-size: 21px;
  font-weight: 500;
}

.action-required {
  font-size: 32px;
  color: #fedb80;
}

.complete {
  font-size: 32px;
  color: #00a200;
}

.failed {
  font-size: 32px;
  color: #cc0101;
}

.delete {
  color: #cc0101;
}

.arva-success {
  background-color: #edf9dc;
}

.eighth-width {
  width: 12.5%;
}

.year-cell {
  min-width: 70px;
}

.crop-cell {
  min-width: 180px;
}

.actions-cell {
  min-width: 60px;
}

.cell-table {
  padding: 0;
}

.cell-padder {
  padding: 2px 6px;
}

.light-blue-bg {
  background-color: #e3f5fc;
}

.test {
  background-color: #0095ff;
  /* background: rgba(107, 153, 206, 1) !important; */
}

.white-bg {
  background-color: white;
}

.gray-bg {
  background-color: #f6f7fb !important;
}

/* crop column */
.crop-column {
  font-size: 18px;
}

.v-input {
  font-size: 18px;
}

/* hide arrows/spin button for number input */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.action-button {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icons-container {
  display: flex;
  align-items: center;
  gap: 4px;
}
.select-cropscape-confirm-wrapper {
  display: flex;
}

.cropscape-unavailable-src {
  background-color: #fedb80;
}
</style>
