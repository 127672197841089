<template>
  <b-td
    :id="'grazing-table-' + rowNum"
    class="cell-table"
    :class="{
      'gray-bg': rowNum % 2 !== 0,
    }"
  >
    <div>
      <b-table-simple class="mb-0" width="100%">
        <b-tbody>
          <GrazingRow
            v-for="grazing in grazingData"
            :addition-view="false"
            :isLocked="isLocked"
            :cropId="cropId"
            :field="fieldId"
            :grazing="grazing"
            :key="grazing.idx"
            :rowIndex="grazingData.indexOf(grazing)"
            :year="year"
            @handle-copy="handleCopyGrazing($event)"
            @bad-input="handleBadInput"
          />
        </b-tbody>
      </b-table-simple>
      <!-- <div
        v-if="!showAddition"
        class="btn btn-primary add-icon icon-column mr-0 pull-right"
        @click="showAddition = true"
      >
        <i class="fa fa-plus" />
      </div> -->
      <div v-if="showAddition" class="border">
        <div class="float-right mr-1 mt-1">
          <i class="fa fa-times-circle fa-2x" @click="resetGrazingToAdd" />
        </div>
        <div class="addition-container">
          <b-table-simple class="mt-4" width="100%">
            <b-tbody>
              <GrazingRow
                :addition-view="true"
                :grazing="grazingToAdd"
                :year="year"
                @bad-input="handleBadInput"
              />
            </b-tbody>
          </b-table-simple>
          <div class="d-flex flex-row justify-content-between my-3">
            <div class="add-text">
              {{ showCopy ? "Copy" : "Add" }} to
              <!-- Re-enable for multifield add/copy -->
              <!-- {{ fieldYearToCopy.length }} -->
              additional years:
            </div>
            <div>
              <v-select-old
                multiple
                class="year-select"
                :options="years"
                v-model="yearSelected"
              />
            </div>
          </div>
          <!-- Re-enable for multifield add/copy -->
          <!-- <div class="d-flex flex-row justify-content-between mt-1">
            <div class="mb-3">
              <div class="d-flex flex-row">
                <span>Years: </span
                ><v-select-old
                  class="ml-2 year-select"
                  multiple
                  :options="years"
                  v-model="yearSelected"
                />
              </div>
            </div>
            <div>
              <v-select-old
                multiple
                class="field-select"
                label="name"
                :options="allFields"
                :reduce="o => o.id"
                v-model="fieldYearToCopy"
              />
            </div>
            <div>
              <button
                type="button"
                class="btn btn-primary py-2"
                @click="handleAddFieldsInFocus"
              >
                Select
                <span class="badge badge-pill badge-success">{{
                  selectedFields.length
                }}</span>
                Fields
              </button>
            </div>
          </div> -->
        </div>
      </div>
      <button
        v-if="showAddition"
        type="button"
        class="btn btn-success btn-lg btn-block"
        @click="handleSubmitGrazing"
      >
        {{ showCopy ? "Copy" : "Submit" }} Grazing <i class="fa fa-plus" />
      </button>
    </div>
  </b-td>
</template>
<script>
import { cloneDeep } from "lodash"
import { mapActions, mapGetters } from "vuex"

import GrazingRow from "@/components/spreadsheet/rows/GrazingRow"
import { Defaults, Filter } from "@/store/modules"

export default {
  name: "GrazingTable",
  components: {
    GrazingRow,
  },
  props: {
    cropId: { type: Number },
    fieldId: { type: String },
    grazingData: { type: Array },
    rowNum: { type: Number },
    year: { type: String },
    years: { type: Array },
    isLocked: { type: Boolean },
  },
  data() {
    return {
      showAddition: false,
      showCopy: false,
      grazingToAdd: {
        crop_id: { source: "User Input", value: null },
        date_start: { source: "User Input", value: null },
        date_end: { source: "User Input", value: null },
        rest_period: { source: "User Input", value: null },
        utilization_pct: {
          source: "User Input",
          value: null,
        },
      },
      fieldYearToCopy: [],
      yearSelected: [],
    }
  },
  computed: {
    ...mapGetters({
      allFields: Filter.Getters.getFields,
      selectedFields: Filter.Getters.getSelectedFields,
    }),
  },
  methods: {
    ...mapActions({
      updateMultipleFieldSettings: Defaults.Actions.updateMultipleFieldSettings,
    }),
    handleAddFieldsInFocus() {
      this.selectedFields.forEach(field => {
        if (
          !this.fieldYearToCopy.includes(this.fieldId) &&
          field.id !== this.fieldId
        ) {
          this.fieldYearToCopy.push(field.id)
        }
      })
    },
    handleCopyGrazing(grazingToCopy) {
      this.showAddition = true
      this.showCopy = true

      this.grazingToAdd = cloneDeep(grazingToCopy)
    },
    handleSubmitGrazing() {
      // TODO Re-enable for multi-field add/copy
      // if (this.showAddition && !this.showCopy) {
      //   this.fieldYearToCopy.push(this.fieldId)
      // }
      this.fieldYearToCopy.push(this.fieldId)
      this.updateMultipleFieldSettings({
        cropId: this.cropId,
        dataCategory: "grazings",
        fieldIds: this.fieldYearToCopy,
        rowData: this.grazingToAdd,
        years: this.yearSelected,
      })
      this.resetGrazingToAdd()
    },
    resetGrazingToAdd() {
      this.grazingToAdd = {
        crop_id: { source: "User Input", value: null },
        date_start: { source: "User Input", value: null },
        date_end: { source: "User Input", value: null },
        rest_period: { source: "User Input", value: null },
        utilization_pct: {
          source: "User Input",
          value: null,
        },
      }
      this.showAddition = false
      this.showCopy = false
      this.fieldYearToCopy = []
      this.yearSelected = [this.year]
    },
    handleBadInput(errorText) {
      this.$emit("bad-input", errorText)
    },
  },
  mounted() {
    if (this.year !== null) this.yearSelected.push(this.year)
  },
}
</script>
<style scoped>
.grazing-bg {
  background-color: #fff5d6;
  /* background-color: #ffe085; */
}

.grazing-bg-dark {
  background-color: #ffe085;
  /* background-color: #ffc100; */
}

.gray-bg {
  background-color: #f6f7fb !important;
}

.addition-container {
  padding-left: 40px;
  padding-right: 84px;
}

.add-icon {
  background-color: #7774e7;
  color: white;
  cursor: pointer;
  padding-left: 14px;
}

.add-icon:hover,
.add-icon:focus {
  color: #ffffff;
  background-color: #8c8aeb;
}

.add-text {
  font-weight: 500;
  font-size: 14px;
}

.alert {
  background-color: #ffb6c1;
}

.arva-generated {
  background-color: #fedb80;
}

.cell-table {
  padding: 0;
}

.complete {
  background-color: #98fb98;
}

.delete {
  color: #cc0101;
}

/* v-select-old widths */
.field-select {
  background-color: white !important;
  width: 360px;
}

.year-select {
  background-color: white !important;
  width: 160px;
  font-size: 13px;
}

/* column sizing */
.crop-column {
  min-width: 130px;
  max-width: 130px;
}

.dates-column {
  min-width: 420px;
  max-width: 420px;
}

.rest-column {
  min-width: 130px;
  max-width: 130px;
}

.util-column {
  min-width: 130px;
  max-width: 130px;
}

.icon-column {
  min-width: 40px;
  max-width: 40px;
}

.icon-copy-column {
  min-width: 44px;
  max-width: 44px;
}
</style>
