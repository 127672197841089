<template>
  <ArvaModal
    name="confirmDeleteInsetOrderModal"
    title="Confirm Inset Program Deletion"
    :width="1100"
    :wide="true"
    @close-modal="handleCloseModal"
  >
    <v-card class="pa-5">
      <h5>
        <span
          >Delete Program
          <v-chip color="green lighten-1">
            {{ currentOrderName }} (ID: {{ currentOrderID }} </v-chip
          >? This can not be undone!</span
        >
        <br />
        <span>
          If this Program is Already Executed, any Fields Allocated to this
          Program will have to be Reallocated.</span
        >
      </h5>

      <!-- also have a input for order name-->
      <ArvaModalSaveFooter
        confirmText="Confirm Delete"
        cancelText="Cancel"
        @on-cancel="handleCloseModal"
        @on-confirm="handleSubmit"
      />
    </v-card>
  </ArvaModal>
</template>

<script>
import ArvaModal from "@/components/modals/ArvaModal"
import ArvaModalSaveFooter from "@/components/modals/ArvaModalSaveFooter"
import CarbonReadyAPI from "@/api/CarbonReadyAPI"

export default {
  name: "ConfirmDeleteInsetOrderModal",
  props: ["currentOrderName", "currentOrderID"],
  components: { ArvaModal, ArvaModalSaveFooter },

  data() {
    return {}
  },

  methods: {
    handleCloseModal() {
      this.$emit("close-modal")
    },
    handleSubmit() {
      let payload = { id: this.currentOrderID }
      CarbonReadyAPI.deleteInsetOrder(payload).then(response => {
        this.$emit("confirm-delete-modal")
      })
    },
  },
}
</script>

<style scoped>
.pw-error {
  color: red;
  font-weight: 500;
}
</style>
