<template>
  <div class="theme-container">
    <div
      v-if="loggedIn"
      class="theme"
      :class="{ 'is-collapsed': collapsed }"
      v-cloak
    >
      <div class="layout">
        <SideNav @toggle-mobile="collapse" />
        <div class="page-container">
          <div class="header navbar">
            <div class="header-container">
              <TopNav @collapse="collapse" />

              <ul class="nav-right">

                <li class="user-profile dropdown">
                  <a
                    class="dropdown-toggle"
                    data-toggle="dropdown"
                    href="javascript:void(0)"
                  >
                    <span v-if="!['production', 'qa', 'demo'].includes(deploymentEnvironment)" color="green lighten-4"
                     class="text-overline env-text green--text">
                      {{ deploymentEnvironment }}
                    </span>
                    <img
                      class="profile-img img-fluid"
                      src="/assets/images/logos/arva-logo-sm.png"
                      alt="User"
                    />
                    <div class="user-info test">
                      <div class="mt-3">
                        <span class="name pdd-right-5">{{ userEmail }}</span>
                        <i class="fa fa-angle-down" />
                      </div>
                      <div>
                        <span class="name pdd-right-5">{{ userRole }}</span>
                        <v-tooltip v-if="isReadOnly" top max-width="300px"
                          ><template v-slot:activator="{ on, attrs }"
                            ><v-icon v-bind="attrs" v-on="on"
                              >mdi-eye</v-icon
                            ></template
                          ><span>{{ tooltip }}</span>
                        </v-tooltip>
                        <v-tooltip
                          v-else-if="isAssigned || isReadWrite"
                          top
                          max-width="300px"
                          ><template v-slot:activator="{ on, attrs }"
                            ><v-icon v-bind="attrs" v-on="on"
                              >mdi-pencil</v-icon
                            ></template
                          ><span>{{ tooltip }}</span>
                        </v-tooltip>
                      </div>
                    </div>
                  </a>
                  <ul class="dropdown-menu user-menu">
                    <li v-if="isStaff">
                      <router-link :to="{ name: 'superuser-admin' }">
                        <i class="fa fa-table pdd-right-10" />
                        <span>SuperUser Admin</span>
                      </router-link>
                    </li>
                    <li v-if="isCustomerAdmin">
                      <router-link :to="{ name: 'customer-admin' }">
                        <i class="fa fa-table pdd-right-10" />
                        <span>Customer Admin</span>
                      </router-link>
                    </li>
                    <li v-else-if="isAssigned">
                      <router-link :to="{ name: 'data-sharing' }">
                        <i class="fa fa-table pdd-right-10" />
                        <span>Data Sharing</span>
                      </router-link>
                    </li>
                    <li>
                      <router-link :to="{ name: 'switch-orgs' }">
                        <i class="fa fa-globe pdd-right-10" />
                        <span>Switch Organization</span>
                      </router-link>
                    </li>
                    <li>
                      <router-link :to="{ name: 'account' }">
                        <i class="fa fa-gear pdd-right-10" />
                        <span>Account</span>
                      </router-link>
                    </li>
                    <li>
                      <a @click.prevent="logout" href="javascript:void(0)">
                        <i class="fa fa-sign-out pdd-right-10" />
                        <span>Logout</span>
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>

          <div class="main-content" @click="handleClick" :class="getPageSpecificClasses">
            <div class="container-fluid">
              <router-view class="mt-5" :key="$route.fullPath" />
            </div>
          </div>
          <!-- <Footer /> -->
        </div>
      </div>
    </div>
    <div v-else class="main-content" v-cloak>
      <div>
        <router-view :key="$route.fullPath" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex"
import { Filter, Theme, User } from "@/store/modules"
import { DEPLOYMENT_ENVIRONMENT } from "@/constants"
import { CSM_VIEW } from "@/constants/contractGroups"
import SideNav from "./SideNav"
// import Footer from "./Footer"
import TopNav from "./TopNav"

export default {
  name: "theme-container",
  components: {
    TopNav,
    SideNav,
  },

  data() {
    return {
      collapsed: false,
      formError: null,
      loginFormData: {
        email: "",
        password: "",
      },
      deploymentEnvironment: DEPLOYMENT_ENVIRONMENT,
    }
  },

  computed: {
    ...mapState({
      view: state => state.EnrollmentGroups.view,
      userToken: state => state.User.token,
      userEmail: state => state.User.user.email,
      userRole: state => state.User.userRole,
      isReadOnly: state => state.User.user.permission === "Read-Only",
      isReadWrite: state => state.User.user.permission === "Read/Write",
      isStaff: state => state.User.user.is_staff,
      isAssigned: state => state.User.user.is_in_assigned_org,
    }),

    getPageSpecificClasses() {
      if (this.$route.fullPath.includes('/enrollment-groups') && this.view == CSM_VIEW) {
        return 'superuser-view'
      }

      if (this.$route.fullPath.includes('/evidencing')) {
        return 'gray-bg-override'
      }

      return ''
    },

    isCustomerAdmin() {
      if (
        this.userRole === "Corporation Admin" ||
        this.userRole === "Organization Admin"
      )
        return true
      return false
    },

    loggedIn() {
      return !!this.userToken
    },

    tooltip() {
      if (this.isReadOnly) {
        return "Read-Only Access: User can view but not modify."
      }
      return "Full Access: User can view and modify."
    },

    isSharedOrg() {
      if (!this.isAssigned) return true
      return false
    },
  },

  methods: {
    ...mapMutations({
      setSideNavOpen: Theme.Mutations.setSideNavOpen,
      closeDropdowns: Filter.Mutations.closeDropdowns,
      userLogout: User.Mutations.logout,
    }),

    ...mapActions({
      logInToAdmin: User.Actions.logInToAdmin,
    }),

    logout() {
      this.userLogout()
      this.$router.push("/login")
      window.Intercom("shutdown")
    },

    collapse() {
      this.collapsed = !this.collapsed
      this.setSideNavOpen(this.collapsed)
    },

    handleClick() {
      this.closeDropdowns()
    },
  },
}
</script>

<style scoped>
.dashboard-scroller {
  overflow: scroll;
}

.user-menu {
  padding-left: 0px !important;
}

.test {
  line-height: 16px !important;
}

.env-text {
  /* matches the logo's margin */
  margin-top: 15px;
  float: left;
}
</style>
