<template>
  <b-tr>
    <!-- date -->
    <b-td
      class="cell-table medium-column"
      :class="getClassForCell(harvest.date)"
    >
      <v-menu
        :disabled="isLocked"
        v-model="showDate"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <div class="d-flex">
            <div class="date-holder">
              <v-text-field
                :disabled="isLocked"
                v-model="date"
                dense
                readonly
                hide-details="true"
                v-bind="attrs"
                v-on="on"
                :append-icon="getAppendIconForCell(harvest.date)"
                @click:append="handleClickSubmit('date', date)"
              ></v-text-field>
            </div>
          </div>
        </template>
        <v-date-picker
          :disabled="isLocked"
          v-model="date"
          :min="year + '-01-01'"
          :max="yearExtendedOne + '-12-31'"
          @input="showDate = false"
          scrollable
        ></v-date-picker>
      </v-menu>
    </b-td>
    <!-- type -->
    <b-td
      class="cell-table medium-column"
      :class="getClassForCell(harvest.harvest_type)"
    >
      <PracticeChoiceDropdown
        :disabled="isLocked"
        clearable
        dense
        hide-details="true"
        :items="harvestTypeChoices"
        choiceName="harvest_type"
        item-text="display_name"
        item-value="value"
        v-model="type"
        :append-icon="getAppendIconForCell(harvest.harvest_type)"
        @click:append="handleClickSubmit('harvest_type', type)"
      />
    </b-td>
    <!-- residue removal -->
    <!-- <b-td
      class="cell-table marge-column"
      :class="{
        'arva-alert': harvest.residue_removal.value == null,
        'pre-gen':
          harvest.residue_removal.source !== 'User Input' &&
          harvest.residue_removal.value !== null,
        complete:
          harvest.residue_removal.source == 'User Input' &&
          harvest.residue_removal.value !== null,
      }"
    >
      <v-slider
        v-model="residueRemoval"
        class="align-center"
        :max="100"
        :min="0"
        hide-details
      ></v-slider>
    </b-td> -->
    <!-- residue removal  -->
    <b-td
      class="cell-table marge-column text-center"
      :class="getClassForCell(harvest.residue_removal)"
    >
      <v-select
        :disabled="isLocked"
        v-model="residueRemoval"
        :items="residueRemovalItems(residueRemoval)"
        item-text="label"
        item-value="value"
        clearable
        dense
        hide-details="true"
        suffix="%"
        :append-icon="getAppendIconForCell(harvest.residue_removal)"
        @click:append="handleClickSubmit('residue_removal', residueRemoval)"
      ></v-select>
      <!-- <v-text-field
        clearable
        dense
        v-model="residueRemoval"
        hide-details="true"
        suffix="%"
        :append-icon="
          harvest.residue_removal.source !== 'User Input' &&
          harvest.residue_removal.value !== null
            ? 'mdi-check-circle-outline'
            : ''
        "
        @keypress="filter($event, 'residue-removal')"
        @click:append="handleClickSubmit('residue_removal', residueRemoval)"
      ></v-text-field> -->
    </b-td>
    <!-- residue burned -->
    <b-td
      class="cell-table medium-column"
      :class="getClassForCell(harvest.residue_burned)"
    >
      <v-select
        :disabled="isLocked"
        clearable
        dense
        hide-details="true"
        :items="yesNoOptions"
        item-text="display_name"
        item-value="value"
        v-model="residueBurned"
        :append-icon="getAppendIconForCell(harvest.residue_burned)"
        @click:append="handleClickSubmit('residue_burned', residueBurned)"
      />
    </b-td>
    <!-- yield -->
    <b-td
      class="cell-table medium-column"
      :class="getClassForCell(harvest.yield)"
    >
      <v-text-field
        :disabled="isLocked"
        clearable
        dense
        v-model="yieldAmt"
        hide-details="true"
        :append-icon="getAppendIconForCell(harvest.yield)"
        @keypress="filter($event, 'yield-amount')"
        @click:append="handleClickSubmit('yieldAmt', yieldAmt)"
      >
      </v-text-field>
    </b-td>
    <!-- units -->
    <b-td
      class="cell-table medium-column"
      :class="getClassForCell(harvest.unit)"
    >
      <v-select
        :disabled="isLocked"
        clearable
        dense
        hide-details="true"
        :items="yieldUnitChoices.filter(item => item.value !== 'g C/m2')"
        item-text="display_name"
        item-value="value"
        v-model="unit"
        :append-icon="getAppendIconForCell(harvest.unit)"
        @click:append="handleClickSubmit('unit', unit)"
      />
    </b-td>
    <b-td class="cell-table">
      <v-btn :disabled="isLocked" icon x-small color="black" @click="handleAdd">
        <v-icon>mdi-plus-circle-outline</v-icon>
      </v-btn>
      <v-btn
        :disabled="isLocked"
        icon
        x-small
        color="black"
        @click="handleDelete"
      >
        <v-icon>mdi-close-circle-outline</v-icon>
      </v-btn>
    </b-td>
  </b-tr>
</template>
<script>
import { mapMutations, mapGetters } from "vuex"

import { CROP_DROPDOWN_OPTIONS, YES_NO_OPTIONS } from "@/constants"
import { Defaults } from "@/store/modules"
import { getAppendIconForCell, getClassForCell } from "../utils"
import PracticeChoiceDropdown from "@/components/dropdowns/PracticeChoiceDropdown"

export default {
  name: "HarvestRow",

  props: {
    additionView: { type: Boolean, default: false },
    cropId: { type: Number },
    field: { type: String },
    harvest: { type: Object },
    rowIndex: { type: Number },
    year: { type: String },
    isLocked: { type: Boolean },
  },
  data() {
    return {
      cropChoices: CROP_DROPDOWN_OPTIONS,
      yesNoOptions: YES_NO_OPTIONS,
      showDate: false,
      getClassForCell,
      getAppendIconForCell,
    }
  },
  components: {
    PracticeChoiceDropdown,
  },
  computed: {
    yearExtendedOne() {
      return String(Number(this.year) + 1)
    },
    ...mapGetters({
      practiceChoices: Defaults.Getters.getPracticeChoices,
      harvestTypeChoices: Defaults.Getters.getHarvestTypeChoices,
    }),
    yieldUnitChoices: {
      get() {
        return this.practiceChoices["harvest_units"]
      },
    },
    crop: {
      get() {
        return this.harvest.crop_id.value
      },
      set(val) {
        const updatePayload = {
          fieldId: this.field,
          year: this.year,
          crop: this.cropId,
          dataCategory: "harvests",
          dataSetting: "crop",
          value: val,
          rowIndex: this.rowIndex,
        }
        this.spreadsheetMutate(updatePayload)
      },
    },
    date: {
      get() {
        const dateReturn = this.harvest.date.value
          ? this.harvest.date.value
          : `${this.year}-01-01`
        return dateReturn
      },
      set(val) {
        const updatePayload = {
          fieldId: this.field,
          year: this.year,
          crop: this.cropId,
          dataCategory: "harvests",
          dataSetting: "date",
          value: val,
          rowIndex: this.rowIndex,
        }
        this.spreadsheetMutate(updatePayload)
      },
    },
    type: {
      get() {
        return this.harvest.harvest_type.value
      },
      set(val) {
        const updatePayload = {
          fieldId: this.field,
          year: this.year,
          crop: this.cropId,
          dataCategory: "harvests",
          dataSetting: "harvest_type",
          value: val,
          rowIndex: this.rowIndex,
        }
        this.spreadsheetMutate(updatePayload)
      },
    },
    residueRemoval: {
      get() {
        return this.harvest.residue_removal.value
      },
      set(val) {
        const updatePayload = {
          fieldId: this.field,
          year: this.year,
          crop: this.cropId,
          dataCategory: "harvests",
          dataSetting: "residue_removal",
          value: val,
          rowIndex: this.rowIndex,
        }
        this.spreadsheetMutate(updatePayload)
      },
    },
    residueBurned: {
      get() {
        return this.harvest.residue_burned.value
      },
      set(val) {
        const updatePayload = {
          fieldId: this.field,
          year: this.year,
          crop: this.cropId,
          dataCategory: "harvests",
          dataSetting: "residue_burned",
          value: val,
          rowIndex: this.rowIndex,
        }
        this.spreadsheetMutate(updatePayload)
      },
    },
    unit: {
      get() {
        return this.harvest.unit.value
      },
      set(val) {
        const updatePayload = {
          fieldId: this.field,
          year: this.year,
          crop: this.cropId,
          dataCategory: "harvests",
          dataSetting: "unit",
          value: val,
          rowIndex: this.rowIndex,
        }
        this.spreadsheetMutate(updatePayload)
      },
    },
    yieldAmt: {
      get() {
        return this.harvest.yield.value
      },
      set(val) {
        if (val === "") {
          val = null
        }
        let validVal = this.handleValidateValue(val, "number")
        if (validVal) {
          const updatePayload = {
            fieldId: this.field,
            year: this.year,
            crop: this.cropId,
            dataCategory: "harvests",
            dataSetting: "yield",
            value: val,
            rowIndex: this.rowIndex,
          }
          this.spreadsheetMutate(updatePayload)
        }
      },
    },
  },
  methods: {
    ...mapMutations({
      addFieldSettingRow: Defaults.Mutations.addFieldSettingRow,
      deleteNewFieldSetting: Defaults.Mutations.deleteNewFieldSetting,
      updateNewFieldSetting: Defaults.Mutations.updateNewFieldSetting,
      spreadsheetMutate: Defaults.Mutations.spreadsheetMutate,
    }),

    handleClickSubmit(setting, val) {
      this[setting] = val
    },
    handleCopy() {
      this.$emit("handle-copy", this.harvest)
    },
    handleAdd() {
      const payloadObj = {
        crop_id: { source: "User Input", value: this.cropId },
        crop_name: { source: "User Input", value: null },
        date: { source: "User Input", value: null },
        harvest_type: { source: "User Input", value: null },
        residue_burned: { source: "User Input", value: null },
        residue_removal: { source: "User Input", value: null },
        unit: { source: "User Input", value: null },
        yield: { source: "User Input", value: null },
      }
      this.addFieldSettingRow({
        fieldId: this.field,
        cropId: this.cropId,
        dataCategory: "harvests",
        dataObj: payloadObj,
        year: this.year,
      })
    },
    handleDelete() {
      var defaultObj = {}
      for (const [key, value] of Object.entries(this.harvest)) {
        if (key === "crop_id") defaultObj[key] = value
        else defaultObj[key] = { source: "User Input", value: null }
      }

      this.deleteNewFieldSetting({
        fieldId: this.field,
        cropId: this.cropId,
        dataCategory: "harvests",
        defaultObj: defaultObj,
        rowIndex: this.rowIndex,
        year: this.year,
      })
    },
    handleValidateValue(val, type, length = 24, range = null) {
      function isNumeric(str) {
        if (typeof str == "number") return true
        return (
          !isNaN(str) && !isNaN(parseFloat(str)) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        ) // ...and ensure strings of whitespace fail
      }

      function isFloat(str) {
        return !Number.isInteger(str)
      }

      if (val === null) {
        return true
      }

      if (val.length > length) {
        this.$emit(
          "bad-input",
          "Inputted value length was too long (24 characters allowed)."
        )
        return false
      }

      if (type == "number") {
        if (isNumeric(val)) {
          if (range !== null) {
            if (val >= range[0] && val <= range[1]) {
              return true
            } else {
              this.$emit(
                "bad-input",
                `Look out! Only a value between ${range[0]} and ${range[1]} can be entered here.`
              )
              return false
            }
          }
          return true
        } else {
          this.$emit(
            "bad-input",
            "Look out! A numerical value is expected here."
          )
          return false
        }
      } else if (type == "string") {
        return true
      } else if (type == "float") {
        if (isFloat(val)) {
          if (range !== null) {
            if (val >= range[0] && val <= range[1]) {
              return true
            } else {
              this.$emit(
                "bad-input",
                `Look out! Only a value between ${range[0]} and ${range[1]} can be entered here.`
              )
              return false
            }
          }
          return true
        } else {
          this.$emit(
            "bad-input",
            "Look out! A numerical value is expected here."
          )
          return false
        }
      }
    },
    residueRemovalItems(residueRemoval) {
      const items = []
      for (let i = 0; i <= 100; i += 10) {
        items.push({ label: `${i}`, value: `${i}` })
        if (
          residueRemoval !== null &&
          residueRemoval >= i &&
          residueRemoval < i + 10
        ) {
          items.push({
            label: `${residueRemoval}`,
            value: `${residueRemoval}`,
            disabled: true,
          })
        }
      }
      return items
    },
    filter(event, fieldInput) {
      event = event ? event : window.event
      let expect = event.target.value.toString() + event.key.toString()

      let range = null
      let inputType = "number"
      if (fieldInput === "yield-amount") {
        range = [0, 350]
        inputType = "float"
      }

      let validVal = this.handleValidateValue(expect, inputType, 24, range)
      if (!validVal) event.preventDefault()
    },
  },
}
</script>
<style scoped>
.arva-alert {
  background-color: #f7c0be;
}

.bg-white {
  color: white;
}

.cell-text {
  font-weight: 600;
  color: white;
}

.cell-table {
  padding: 2px 6px;
}

.pre-gen {
  background-color: #fedb80;
}
.machine {
  background-color: #c0dce2;
}
.complete {
  background-color: #c7e3c1;
}

.copy-icon {
  cursor: pointer;
  color: #0f9aee;
}

.date-style {
  font-weight: 600px;
}

.delete {
  color: #cc0101;
  cursor: pointer;
}

.input-check {
  cursor: pointer;
}

.select-check {
  margin-top: 14px;
  cursor: pointer;
}

.submit-icon {
  color: #c7e3c1;
}

.medium-column {
  width: 140px;
}

.large-column {
  width: 240px;
}

.marge-column {
  width: 180px;
}

.v-input {
  font-size: 18px;
}
</style>
