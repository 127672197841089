<template>
  <div>
    <Permissions package="sustainability" />
    <EvidencingActionSidebar
      :action="tableActionSelection"
      :evidencing="
        sortedFilteredEvidencing.filter(({ id }) =>
          tableData['selectedRows'].includes(id)
        )
      "
      :visible="showTableActionSidebar"
      @closeSidebar="handleClosedSidebar"
    />

    <div v-if="loading[ACTIVE_EVIDENCING_GROUP]">
      <v-progress-circular indeterminate :size="48" color="#79c61c" />
    </div>
    <div v-else-if="showSustainability" class="map-wrapper">
      <EvidencingActionButtons
        :activeBtn="showEvidencingTableView ? EVIDENCING_TABLE : EVIDENCING_MAP"
        @toggleDataView="showEvidencingTableView = !showEvidencingTableView"
        :viewingAllEvidencing="viewingAll"
      />

      <EvidencingMap
        v-if="!showEvidencingTableView"
        :evidencing="sortedFilteredEvidencing"
      />

      <div v-if="!showEvidencingTableView" class="evidencing-table-filters map-evidencing-filters">
        <div class="evidencing-table-filter-selections activated">
          <div class="table-filter-selector">
            <p>Crop</p>
            <v-select
              :menu-props="{ bottom: true, offsetY: true }"
              v-model="tableData['filters']['crop']"
              :items="totalEvidencingCrops"
              :class="tableData['filters']['crop'] != null ? 'has-items' : ''"
              label="Crop"
              clearable
              dense
              solo
            />
          </div>

          <div class="table-filter-selector">
            <p>Uploads</p>
            <v-select
              :menu-props="{ bottom: true, offsetY: true }"
              v-model="tableData['filters']['uploads']"
              :items="uploadItems"
              :class="tableData['filters']['uploads'] != null ? 'has-items' : ''"
              label="Uploads"
              clearable
              dense
              solo
            />
          </div>

          <div class="table-filter-selector">
            <p>Type</p>
            <v-select
              :menu-props="{ bottom: true, offsetY: true }"
              v-model="tableData['filters']['evidencingTypes']"
              :items="totalEvidencingTypes"
              :class="tableData['filters']['evidencingTypes'].length > 0 ? 'has-items' : ''"
              label="Evidencing Type"
              clearable
              multiple
              dense
              solo
            >
              <template v-slot:selection="{ item, index }">
                <p class="ellipsized-p" v-if="index == 0">
                  {{ item }}
                </p>
              </template>
            </v-select>
          </div>

          <div class="table-filter-selector">
            <p>Fields</p>
            <v-select
              :menu-props="{ bottom: true, offsetY: true }"
              v-model="tableData['filters']['fields']"
              :items="totalEvidencingFields"
              :class="tableData['filters']['fields'].length > 0 ? 'has-items' : ''"
              label="Fields"
              clearable
              multiple
              dense
              solo
            >
              <template v-slot:selection="{ item, index }">
                <p class="ellipsized-p" v-if="index == 0">
                  {{ item['text'] }}
                </p>
              </template>
            </v-select>
          </div>

          <div class="table-filter-selector">
              <p>Status</p>
              <v-select
                :menu-props="{ bottom: true, offsetY: true }"
                v-model="tableData['filters']['status']"
                :items="Object.values(EVIDENCING_STATUSES)"
                :class="tableData['filters']['status'].length > 0 ? 'has-items' : ''"
                label="Status"
                clearable
                multiple
                dense
                solo
              >
                <template v-slot:selection="{ item, index }">
                  <p class="ellipsized-p" v-if="index == 0">
                    {{ item }}
                  </p>
                </template>
              </v-select>
            </div>

          <div class="fields-search">
            <v-icon>mdi-magnify</v-icon>
            <input
              type="text"
              placeholder="Search by field..."
              v-model="tableData['fieldSearch']"
            />
          </div>
        </div>
      </div>

      <div class="evidencing-table-view">
        <div
          class="evidencing-table-view-overlay"
          @click="showEvidencingTableView = false"
        />

        <div class="evidencing-table-view-header-wrapper">
          <h1>
            <a @click="router.push('/evidencing/')"><v-icon>mdi-chevron-left</v-icon></a>
            {{ getGroupName }}
          </h1>
        </div>

        <EvidencingPageTabs :activeBtn="EVIDENCING_TABLE" />

        <div class="evidencing-table-filters">
          <div class="evidencing-table-filter-selections activated">
            <div class="table-filter-selector">
              <p>Crop</p>
              <v-select
                :menu-props="{ bottom: true, offsetY: true }"
                v-model="tableData['filters']['crop']"
                :items="totalEvidencingCrops"
                :class="tableData['filters']['crop'] != null ? 'has-items' : ''"
                label="Crop"
                clearable
                dense
                solo
              />
            </div>

            <div class="table-filter-selector">
              <p>Uploads</p>
              <v-select
                :menu-props="{ bottom: true, offsetY: true }"
                v-model="tableData['filters']['uploads']"
                :items="uploadItems"
                :class="tableData['filters']['uploads'] != null ? 'has-items' : ''"
                label="Uploads"
                clearable
                dense
                solo
              />
            </div>

            <div class="table-filter-selector">
              <p>Type</p>
              <v-select
                :menu-props="{ bottom: true, offsetY: true }"
                v-model="tableData['filters']['evidencingTypes']"
                :items="totalEvidencingTypes"
                :class="tableData['filters']['evidencingTypes'].length > 0 ? 'has-items' : ''"
                label="Evidencing Type"
                clearable
                multiple
                dense
                solo
              >
                <template v-slot:selection="{ item, index }">
                  <p class="ellipsized-p" v-if="index == 0">
                    {{ item }}
                  </p>
                </template>
              </v-select>
            </div>

            <div class="table-filter-selector">
              <p>Fields</p>
              <v-select
                :menu-props="{ bottom: true, offsetY: true }"
                v-model="tableData['filters']['fields']"
                :items="totalEvidencingFields"
                :class="tableData['filters']['fields'].length > 0 ? 'has-items' : ''"
                label="Fields"
                clearable
                multiple
                dense
                solo
              >
                <template v-slot:selection="{ item, index }">
                  <p class="ellipsized-p" v-if="index == 0">
                    {{ item['text'] }}
                  </p>
                </template>
              </v-select>
            </div>

            <div class="table-filter-selector">
              <p>Status</p>
              <v-select
                :menu-props="{ bottom: true, offsetY: true }"
                v-model="tableData['filters']['status']"
                :items="Object.values(EVIDENCING_STATUSES)"
                :class="tableData['filters']['status'].length > 0 ? 'has-items' : ''"
                label="Status"
                clearable
                multiple
                dense
                solo
              >
                <template v-slot:selection="{ item, index }">
                  <p class="ellipsized-p" v-if="index == 0">
                    {{ item }}
                  </p>
                </template>
              </v-select>
            </div>

            <div class="fields-search">
              <v-icon>mdi-magnify</v-icon>
              <input
                type="text"
                placeholder="Search by field..."
                v-model="tableData['fieldSearch']"
              />
            </div>
          </div>
        </div>

        <div class="evidencing-table-wrapper" v-if="showEvidencingTableView">
          <div v-if="tableData['selectedRows'].length > 0" class="evidencing-action-bar">
            <h2>
              {{ tableData["selectedRows"].length }} Request{{
                tableData["selectedRows"].length == 1 ? "" : "s"
              }}
            </h2>

            <v-btn
              @click="handleActionSelect(ATTEST_DATA_ACTION)"
              color="#FFFFFF"
              outlined
              height="44px"
            >
              Mark for Attestation
            </v-btn>

            <v-btn
              @click="handleActionSelect(UPLOAD_DATA_ACTION)"
              color="#FFFFFF"
              outlined
              height="44px"
            >
              Upload Evidencing
            </v-btn>

            <v-btn
              @click="exportEvidencingTable"
              color="#FFFFFF"
              outlined
              height="44px"
            >
              Export
            </v-btn>
          </div>

          <div class="no-data-found" v-else-if="errorMsg.length > 0">
            <p>{{ errorMsg }}</p>
          </div>

          <div
            class="evidencing-group"
            v-if="sortedFilteredEvidencing.length > 0"
          >
            <h2 v-if="tableData['selectedRows'].length == 0">
              {{ sortedFilteredEvidencing.length }} Requests
            </h2>

            <v-simple-table class="evidencing-dashboard-table">
              <thead>
                <tr>
                  <th class="checkbox-head">
                    <v-checkbox
                      @click="allRowSelectClick"
                      v-model="tableData['allRowSelection']"
                    />
                  </th>
                  <th
                    v-for="(head, idx) in tableData['headers']"
                    :key="head + idx"
                    :class="`text-left ${idx == 0 ? 'assigned-head' : ''}`"
                    :data-header="head.toLowerCase()"
                  >
                    <a @click="sortTable(head.toLowerCase())">
                      {{ head }}

                      <div class="evidencing-table-sorters">
                        <font-awesome-icon
                          :icon="['fas', 'caret-up']"
                          :class="getSortingState(head.toLowerCase(), 0)"
                        />
                        <font-awesome-icon
                          :icon="['fas', 'caret-down']"
                          :class="getSortingState(head.toLowerCase(), 1)"
                        />
                      </div>
                    </a>
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="c in sortedFilteredEvidencing"
                  :key="c['id'] + c['confirmation_number']"
                  :class="getHighlightedClassState(c)"
                >
                  <td class="has-checkbox">
                    <v-checkbox
                      :value="c['id']"
                      v-model="tableData['selectedRows']"
                    />
                  </td>
                  <td>{{ c["id"] }}</td>
                  <td>
                    {{ truncateByChars(c["formatted_confirmation_type"], 21) || "None" }}
                  </td>
                  <td>{{ truncateByChars(c["client"]["name"], 12) }}</td>
                  <td>{{ truncateByChars(getEvidencingFarm(c), 12) }}</td>
                  <td
                    :class="
                      c['fields'] && c['fields'].length > 2
                        ? 'field-has-tooltip'
                        : ''
                    "
                  >
                    {{ truncateByChars(getEvidencingField(c), 12) }}
                  </td>
                  <td>{{ getFormattedCrop(c["crops"]) }}</td>
                  <td>{{ c['deadline'] ? new Date(c["deadline"]).toLocaleDateString() : 'None' }}</td>
                  <td>
                    <a :class="`evidencing-upload-status evidencing-status-${c['status']}`">
                      {{ c['status'].replace('_', ' ') }}
                    </a>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </div>

          <div class="no-data-found" v-else>
            <p>No evidencing data to display. If you have filters enabled, try clearing them.</p>
            <p>You can also try changing the year or navbar selections (Client, Farms, Fields, etc.)</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Filter } from "@/store/modules";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import {
  ATTEST_DATA_ACTION,
  UPLOAD_DATA_ACTION,
  ASSIGN_USERS_ACTION,
  CLIENT_SPECIFICITY,
  FARM_SPECIFICITY,
  FIELD_SPECIFICITY
} from "@/constants";
import {
  EVIDENCING_MAP,
  EVIDENCING_TABLE,
  ACTIVE_EVIDENCING_GROUP,
  EVIDENCING_STATUSES
} from "@/constants/evidencing";
import editIcon from "@/assets/images/edit-icon.svg";
import validateIcon from "@/assets/images/validate-icon.svg";
import fileIcon from "@/assets/images/file-icon.svg";
import mapIcon from "@/assets/images/map-icon.svg";
import tableIcon from "@/assets/images/table-icon.svg";
import uploadIcon from "@/assets/images/upload-icon.svg";
import Permissions from "@/components/permissions/Permissions";
import EvidencingActionSidebar from "@/components/evidencing/EvidencingActionSidebar";
import EvidencingMap from "@/components/evidencing/EvidencingMap";
import EvidencingActionButtons from "@/components/evidencing/EvidencingActionButtons";
import EvidencingPageTabs from "../components/evidencing/EvidencingPageTabs";
import router from "@/router";
import { registerAttestationOnlyEvidencing } from "@/api/EvidencingAPI";
import { Evidencing } from "@/store/modules";

export default {
  name: "EvidencingGroupTableView",
  components: {
    Permissions,
    EvidencingActionSidebar,
    EvidencingMap,
    EvidencingActionButtons,
    EvidencingPageTabs
  },
  data() {
    return {
      errorMsg: '',
      showEvidencingTableView: true,
      showTableActionSidebar: false,
      tableActionSelection: "",
      fieldMarkerMap: {},
      markerList: [],
      highlightedField: null,
      map: null,
      zoomControl: null,
      tableData: {
        page: 0,
        editingFilters: false,
        fieldSearch: "",
        filters: {
          crop: null,
          uploads: null,
          status: [],
          evidencingTypes: [],
          fields: []
        },
        selectedRows: [],
        allRowSelection: false,
        sort: {
          key: null,
          state: 0,
        },
        headers: [
          "Id",
          "Type",
          "Client",
          "Farm",
          "Field",
          "Crop",
          "Due",
          "Status",
        ],
      },
      uploadItems: [
        {
          text: "Has Uploads",
          value: 1,
        },
        {
          text: "No Uploads",
          value: 2,
        },
      ],
      mapIcon,
      fileIcon,
      tableIcon,
      uploadIcon,
      editIcon,
      validateIcon,
      ATTEST_DATA_ACTION,
      UPLOAD_DATA_ACTION,
      ASSIGN_USERS_ACTION,
      CLIENT_SPECIFICITY,
      FARM_SPECIFICITY,
      FIELD_SPECIFICITY,
      router,
      EVIDENCING_TABLE,
      EVIDENCING_MAP,
      ACTIVE_EVIDENCING_GROUP,
      EVIDENCING_STATUSES
    }
  },
  methods: {
    ...mapMutations({
      toggleField: Filter.Mutations.toggleItem,
    }),
    ...mapActions({
      fetchAllOrgEvidencingData: Evidencing.Actions.fetchAllOrgEvidencingData,
      fetchEvidencingGroupData: Evidencing.Actions.fetchEvidencingGroupData,
    }),
    truncateByChars(text, charLimit) {
      if (text != null) {
        if (text.length <= charLimit) return text
        return `${text.slice(0, charLimit)}...`
      }
    },
    handleClosedSidebar() {
      this.showTableActionSidebar = false;

      if (this.viewingOrgWideEvidencing) {
        this.fetchAllOrgEvidencingData({ year: this.year, organization: this.organization.id });
      }
      else {
        this.fetchEvidencingGroupData({ year: this.year, groupId: this.activeEvidencingGroup['id'] });
      }
    },
    handleActionSelect(action) {
      if (action == ATTEST_DATA_ACTION) {
        const evidencingNumbers = this.sortedFilteredEvidencing
          .filter(({ id }) => this.tableData['selectedRows'].includes(id))
          .map(({ confirmation_number }) => confirmation_number);

        registerAttestationOnlyEvidencing({ confirmation_numbers: evidencingNumbers })
        .then(_ => this.handleClosedSidebar())
      }
      else {
        this.tableActionSelection = action
        this.showTableActionSidebar = true
      }
    },
    allRowSelectClick() {
      if (!this.tableData["allRowSelection"]) {
        this.tableData["selectedRows"] = []
      } else {
        this.tableData["selectedRows"] = this.sortedFilteredEvidencing.map(
          ({ id }) => id
        )
      }
    },
    getSortingState(header, state) {
      return this.tableData["sort"]["key"] == header &&
        this.tableData["sort"]["state"] == state
        ? "activated-sort"
        : ""
    },
    sortTable(header) {
      if (this.tableData["sort"]["key"] != null) {
        if (this.tableData["sort"]["key"] == header) {
          if (this.tableData["sort"]["state"] == 0) {
            this.tableData["sort"]["state"] += 1
          } else {
            this.tableData["sort"]["key"] = null
            this.tableData["sort"]["state"] = 0
          }
        } else {
          this.tableData["sort"]["key"] = header
          this.tableData["sort"]["state"] = 0
        }
      } else {
        this.tableData["sort"]["key"] = header
        this.tableData["sort"]["state"] = 0
      }
    },
    getEvidencingField(evidencing) {
      if (evidencing["fields"].length > 2) {
        return `${evidencing["fields"]
          .slice(0, 2)
          .map(({ name }) => name)
          .join(", ")} + ${evidencing["fields"].length} more`
      } else if (evidencing["fields"].length > 0) {
        return evidencing["fields"].map(({ name }) => name).join(", ")
      }

      return "Not Specified"
    },
    getEvidencingFarm(evidencing) {
      if (
        evidencing["farms"] != undefined &&
        evidencing["farms"].length > 0
      )
        return evidencing["farms"][0]["name"]

      return "Not Specified"
    },
    highlightTable({ fieldId = null }) {
      this.showEvidencingTableView = true
      this.highlightedField = fieldId
    },
    getFormattedCrop(crops) {
      if (crops != undefined && crops.length > 0) {
        let cropStr = ""

        for (let i = 0; i < crops.length; i++) {
          const { name } = crops[i]
          cropStr += `${name[0].toUpperCase()}${name.slice(1)}`
          if (i != crops.length - 1) cropStr += "\n"
        }

        return cropStr
      }

      return "Not Specified"
    },
    getHighlightedClassState(evidencing) {
      if (evidencing["fields"] != null && evidencing["fields"].length > 0) {
        return this.highlightedField &&
          evidencing["fields"].find(({ id }) => id == this.highlightedField)
          ? "highlighted"
          : ""
      }

      return ""
    },
    exportEvidencingTable() {
      // pretty hard coded for now, near future we'll have a sidebar for this too
      const csvHeader = [
        "ID",
        "Type",
        "Organization",
        "Client",
        "Farm(s)",
        "Field(s)",
        "Crop",
        "Due",
        "Status",
        "Evidencing Source",
        "Source Details",
        "Instructions",
        "Notes",
      ]
      const csvRows = []

      const selectedEvidencing = this.sortedFilteredEvidencing.filter(
        ({ id }) => this.tableData["selectedRows"].includes(id)
      )

      for (const conf of selectedEvidencing) {
        const row = []

        row.push(
          conf["id"],
          conf["formatted_confirmation_type"] || "Not Specified",
          conf["organization_node"]
            ? conf["organization_node"]["name"]
            : "Not Specified",
          conf["client"] ? conf["client"]["name"] : "Not Specified",
          conf["farms"] && conf["farms"].length > 0
            ? conf["farms"].map(({ name }) => name).join("\n")
            : "Not Specified",
          conf["fields"] && conf["fields"].length > 0
            ? conf["fields"].map(({ name }) => name).join("\n")
            : "Not Specified",
          conf["crops"] && conf["crops"].length > 0
            ? conf["crops"].map(({ name }) => name).join("\n")
            : "Not Specified",
          conf["year"] ? conf["year"] : "Not Specified",
          Boolean(conf["hasUploads"]) ? "Submitted" : "Unsubmitted"
        )

        if (conf["confirmation_source"] == "other") {
          row.push(`Other - ${conf["other_source_manual_specification"]}`)
        } else {
          row.push(conf["confirmation_source"] || "Not Specified")
        }

        row.push(conf["confirmation_source_details"])

        row.push(
          conf["instructions"] || "Not Specified",
          conf["notes"] ? `\" Notes: ${conf["notes"]} \"` : "Not Specified"
        )

        csvRows.push(row)
      }

      let csv = "data:text/csv;charset=utf-8,"
      csv += `${csvHeader}\n`
      csv += csvRows.join("\n")

      const encodedUri = encodeURI(csv)
      const link = document.createElement("a")
      link.setAttribute("href", encodedUri)
      link.setAttribute("download", "evidencing_table_export.csv")
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
  },
  computed: {
    ...mapGetters({
      activeFields: Filter.Getters.getSelectedFields,
      activeFarms: Filter.Getters.getFarms,
      activeClients: Filter.Getters.getClients,
      evidencingData: Evidencing.Getters.getFormattedEvidencing
    }),
    ...mapState({
      fieldBoundaries: state => state.Map.fieldBoundaries,
      organization: state => state.Organization.organization,
      year: state => state.Organization.year,
      showSustainability: state =>
        state.Organization.organization.showSustainability,
      user: state => state.User.user,
      activeEvidencingGroup: state => state.Evidencing.activeEvidencingGroup,
      loading: state => state.Evidencing.loading,
      viewingOrgWideEvidencing: state => state.Evidencing.viewingOrgWideEvidencing
    }),
    sortedFilteredEvidencing() {
      if (this.tableData["sort"]["key"] == null)
        return this.evidencingTableFiltered

      return this.evidencingTableFiltered.toSorted((a,b) => {
        const sortKey = this.tableData['sort']['key'];
        const sortDir = this.tableData['sort']['state'];

        if (sortKey == 'type') {
          if (a['formatted_confirmation_type'] != null && b['formatted_confirmation_type'] != null) {
            return sortDir
              ? b['formatted_confirmation_type'].localeCompare(a['formatted_confirmation_type'])
              : a['formatted_confirmation_type'].localeCompare(b['formatted_confirmation_type']);
          }
        }
        if (sortKey == 'status') {
          if (a['status'] != null && b['status'] != null)
            return sortDir
            ? b['status'].localeCompare(a['status'])
            : a['status'].localeCompare(b['status']);
        }
        if (sortKey == 'id') {
          if (a['id'] != null && b['id'] != null)
            return sortDir ? b['id'] - a['id'] : a['id'] - b['id']
        }
        if (sortKey == 'farm') {
          const aFarm = a['farms'] != null && a['farms'].length > 0 ? a['farms'][0]['name'] : null;
          const bFarm = b['farms'] != null && b['farms'].length > 0 ? b['farms'][0]['name'] : null;

          if (aFarm != null && bFarm != null) 
            return sortDir ? bFarm.localeCompare(aFarm) : aFarm.localeCompare(bFarm);
        }
        if (sortKey == 'client') {
          const aClient = a['client'] != null ? a['client']['name'] : null;
          const bClient = b['client'] != null ? b['client']['name'] : null;

          if (aClient != null && bClient != null) 
            return sortDir ? bClient.localeCompare(aClient) : aClient.localeCompare(bClient);
        }
        if (sortKey == 'crop') {
          const aCrop = a['crops'] != null && a['crops'].length > 0 ? a['crops'][0]['name'] : null;
          const bCrop = b['crops'] != null && b['crops'].length > 0 ? b['crops'][0]['name'] : null;

          if (aCrop != null && bCrop != null)
            return sortDir ? bCrop.localeCompare(aCrop) : aCrop.localeCompare(bCrop);
        }
        if (sortKey == 'field') {
          const aField = a['fields'] != null && a['fields'].length > 0 ? a['fields'][0]['name'] : null;
          const bField = b['fields'] != null && b['fields'].length > 0 ? b['fields'][0]['name'] : null;

          if (aField != null && bField != null)
            return sortDir ? bField.localeCompare(aField) : aField.localeCompare(bField);
        }
        if (sortKey == 'due') {
          const aDeadline = a['deadline'] != null ? a['deadline'] : null
          const bDeadline = b['deadline'] != null ? b['deadline'] : null

          if (aDeadline != null && bDeadline != null) {
            return sortDir ? bDeadline.localeCompare(aDeadline) : aDeadline.localeCompare(bDeadline);
          }
        }

        return 0
      });
    },
    evidencingTableFiltered() {
      const filteredByTableOrNav = this.evidencingData.filter(({
        crops,
        fields,
        status,
        uploads,
        formatted_confirmation_type,
      }) => {
        if (this.tableData['filters']['fields'] != null && this.tableData['filters']['fields'].length > 0) {
          if (!fields.some(({ id }) => this.tableData['filters']['fields'].includes(id)) || fields == null) {
            return false;
          }
        }

        if (this.tableData['filters']['crop'] != null) {
          if (!crops.some(({ name }) => this.tableData['filters']['crop'] == name) || crops == null) {
            return false;
          }
        }

        if (this.tableData['filters']['status'].length > 0) {
          if (!this.tableData['filters']['status'].includes(status)) {
            return false
          }
        }

        if (this.tableData["filters"]["uploads"] != null) {
          if (this.tableData["filters"]["uploads"] == 1) {
            if (uploads.length == 0) return false
          }
          if (this.tableData["filters"]["uploads"] == 2) {
            if (uploads.length > 0) return false
          }
        }

        if (this.tableData["filters"]["evidencingTypes"].length != 0) {
          if (!this.tableData["filters"]["evidencingTypes"].includes(formatted_confirmation_type)) {
            return false
          }
        }

        return true;
      });

      if (this.tableData['fieldSearch'].length == 0) return filteredByTableOrNav;

      return filteredByTableOrNav.filter(({ fields }) => {
        let includesMatchingField = false
        for (const field of fields) {
          if (
            field["name"]
              .toLowerCase()
              .includes(this.tableData["fieldSearch"].toLowerCase())
          )
            includesMatchingField = true
        }
        return includesMatchingField
      })
    },
    viewingAll() {
      return this.$route.fullPath.includes('/all');
    },
    isSuperUser() {
      if (this.user != null) {
        return this.user['is_superuser'];
      }
      
      return false;
    },
    getGroupName() {
      if (this.viewingOrgWideEvidencing) return "All Evidencing";

      if (this.activeEvidencingGroup != null && this.activeEvidencingGroup['name'] != null) {
        return `${this.activeEvidencingGroup['name']} (id: ${this.activeEvidencingGroup['id']})`;
      }

      return 'Not Specified';
    },
    totalEvidencingTypes() {
      const allTypes = []

      for (const { formatted_confirmation_type, year } of this.evidencingData) {
        if (!allTypes.includes(formatted_confirmation_type) && year == this.year) {
          allTypes.push(formatted_confirmation_type)
        }
      }

      return allTypes
    },
    totalEvidencingFields() {
      const allFields = []

      for (const { fields, year } of this.evidencingData) {
        if (year != this.year) continue

        for (const { id, name } of fields) {
          if (!allFields.includes(name)) {
            allFields.push({id, name})
          }
        }
      }

      return allFields.map(({ id, name }) => {
        return {
          text: `${name.slice(0, 1).toUpperCase()}${name.slice(1)}`,
          value: id,
        }
      })
    },
    totalEvidencingCrops() {
      const allCrops = [];

      for (const { crops, year } of this.evidencingData) {
        if (year != this.year) continue

        for (const { name } of crops) {
          if (!allCrops.includes(name)) {
            allCrops.push(name)
          }
        }
      }

      return allCrops.map(n => {
        return {
          text: `${n.slice(0, 1).toUpperCase()}${n.slice(1)}`,
          value: n,
        }
      })
    },
    iconSvg() {
      return `
        <svg viewBox="0 0 384 512">
          <path d="M384 192c0 87.4-117 243-168.3 307.2c-12.3 15.3-35.1 15.3-47.4 0C117 435 0 279.4 0 192C0 86 86 0 192 0S384 86 384 192z"/>
        </svg>
      `
    }
  },
  async mounted() {
    if (this.activeEvidencingGroup == null && !this.loading[ACTIVE_EVIDENCING_GROUP]) {
      router.push('/evidencing/');
    }

    const presetActiveDataView = this.$route.query['openView'];
    if (presetActiveDataView != null) {
      if (presetActiveDataView == EVIDENCING_MAP) {
        this.showEvidencingTableView = false;
      }
      else if (presetActiveDataView == EVIDENCING_TABLE) {
        this.showEvidencingTableView = true;
      }
    }
  },
  watch: {
    showTableActionSidebar(curr) {
      if (!curr) {
        this.tableActionSelection = ""
      }
    },
    tableData: {
      handler(curr) {
        if (
          curr["selectedRows"].length != this.sortedFilteredEvidencing.length
        ) {
          this.tableData["allRowSelection"] = false
        }
      },
      deep: true,
    }
  }
}
</script>

<style scoped>
.map-wrapper {
  position: relative;
  background: #1b1b1d;
  height: calc(100vh - 65px);
  width: calc(100% + 60px);
  left: -30px;
  overflow: hidden;
  margin-top: -20px;
}
.evidencing-table-view {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #f4f4f4;
  padding: 36px 24px;
  z-index: 2;
}
.evidencing-table-view-header-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.evidencing-table-view-header-wrapper h1 {
  font-size: 22px;
  line-height: 33px;
  color: #000000;
  font-weight: bold;
  margin-bottom: 0 !important;
}
.evidencing-table-view-header-wrapper h1 a {
  display: inline-flex;
}
.evidencing-table-view-header-wrapper h1 a i {
  color: #000000;
}
.evidencing-table-wrapper {
  height: calc(100% - 200px); /* 100% - title/btns height - filters height */
}
.evidencing-table-view-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 100%;
  top: 0;
  cursor: pointer;
}
.evidencing-group {
  overflow-x: hidden;
  overflow-y: scroll;
  height: 100%;
  border-radius: 8px;
  background: #ffffff;
}
.evidencing-group > h2 {
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  padding: 24px;
  font-weight: bold;
  margin: 0;
}

table {
  width: 100%;
  border: 1px solid #d9d9d9;
  box-shadow: 0 4px 4px 0 #d9d9d9;
  padding: 16px;
  table-layout: fixed;
}
tr {
  vertical-align: middle;
}
th,
td {
  padding: 0 6px 0 16px !important;
  box-sizing: border-box;
  border-top: none;
  border-bottom: 1px solid #d9d9d9 !important;
}
th:last-of-type {
  padding-right: 16px !important;
}
th.checkbox-head,
td.has-checkbox {
  padding: 0 12px 0 16px !important;
}
th.checkbox-head ::v-deep(i.primary--text),
td.has-checkbox ::v-deep(i.primary--text) {
  color: rgba(0, 0, 0, 0.6) !important;
}
th.checkbox-head ::v-deep(.v-input--selection-controls__ripple),
td.has-checkbox ::v-deep(.v-input--selection-controls__ripple) {
  display: none;
}
th.checkbox-head {
  width: 24px;
  border-right: none;
}
th.assigned-head {
  padding-left: 10px !important;
}
th {
  position: sticky;
  top: 0;
  z-index: 1;
  color: #757575;
  background: #ffffff;
  text-transform: uppercase;
  height: 43px !important;
}
th[data-header="farm"],
th[data-header="crop"] {
  width: 75px;
}
th[data-header="client"] {
  width: 87px;
}
th[data-header="field"] {
  width: 93px;
}
th[data-header="id"] {
  width: 95px;
}
th[data-header="due"] {
  width: 100px;
}
th[data-header="type"] {
  width: 138px;
}
th[data-header="status"] {
  width: 160px;
}
th a {
  padding: 12px 0;
  font-size: 12px;
  line-height: 20px;
  color: #000000 !important;
  display: flex;
  width: 100%;
}
th a .evidencing-table-sorters {
  margin-left: auto;
  position: relative;
}
th a .evidencing-table-sorters > svg {
  color: #cbd5e0;
}
th a .evidencing-table-sorters > svg.activated-sort {
  color: #687588;
}
th a .evidencing-table-sorters > svg:first-of-type {
  position: absolute;
  top: 1px;
  right: 0;
}
th a .evidencing-table-sorters > svg:last-of-type {
  position: absolute;
  bottom: 1px;
  right: 0;
}
th,
td {
  font-size: 14px;
  line-height: 20px;
}
td {
  white-space: pre-wrap;
}
td.has-checkbox {
  border-right: 1px solid #d9d9d9;
}
td.field-has-tooltip {
  color: #054789;
  position: relative;
  cursor: pointer;
}
td.field-has-tooltip .evidencing-fields-tooltip {
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  z-index: 1;
  top: -50%;
  right: -275px;
  background: white;
  border-radius: 4px;
  padding: 16px 12px;
  width: 300px;
  max-height: 400px;
  box-shadow: 0 0 15px 2px rgba(0, 0, 0, 0.1);
  transition: opacity 0.25s;
  opacity: 0;
  pointer-events: none;
  overflow-y: scroll;
  overflow-x: hidden;
}
td.field-has-tooltip .evidencing-fields-tooltip > p {
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.87);
  width: 100%;
  line-height: 1.5;
  white-space: nowrap;
}
td.field-has-tooltip:hover .evidencing-fields-tooltip {
  opacity: 1;
  pointer-events: all;
}
td.evidencing-number-column {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
td a.evidencing-upload-status {
  display: flex;
  align-items: center;
  width: min-content;
  padding: 2px 12px;
  font-size: 12px;
  line-height: 20px;
  font-weight: bold;
  border-radius: 4px;
  white-space: nowrap;
  text-transform: capitalize;
}
td a.evidencing-upload-status.evidencing-status-pending_attestation {
  color: rgba(0, 0, 0, 0.87);
  background: #c7e3c1;
}
td a.evidencing-upload-status.evidencing-status-unsubmitted {
  color: #d22f19;
  background: rgba(255, 122, 0, 0.2);
}
td a.evidencing-upload-status.evidencing-status-submitted {
  color: rgba(0, 0, 0, 0.87);
  background: #c7e3c1;
}
td a.evidencing-upload-status.evidencing-status-validated {
  color: rgba(0, 0, 0, 0.87);
  background: #c7e3c1;
}
.evidencing-dashboard-table ::v-deep(.v-input--checkbox),
.evidencing-dashboard-table
  ::v-deep(.v-input--checkbox .v-input--selection-controls__input),
.evidencing-dashboard-table ::v-deep(.v-input--checkbox .v-input__slot) {
  margin: 0;
  padding: 0;
}
.evidencing-dashboard-table ::v-deep(.v-input--checkbox .v-messages) {
  display: none;
}
.evidencing-table-filters button {
  padding: 0 !important;
  min-width: unset !important;
  margin: 0 0 0 10px;
}
.evidencing-table-filter-selections {
  padding: 24px 0;
  display: flex;
}
.evidencing-table-filter-selections .fields-search {
  width: 100%;
  max-width: 175px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 4px 12px;
  display: flex;
  flex-wrap: nowrap;
  align-self: flex-end;
  margin-left: auto;
}
.evidencing-table-filter-selections .fields-search i {
  margin-right: 12px;
}
.evidencing-table-filter-selections .fields-search input {
  width: 100%;
}
.evidencing-table-filter-selections .table-filter-selector {
  margin-right: 12px;
  width: 140px;
}
.evidencing-table-filter-selections .table-filter-selector > p {
  font-size: 12px;
  line-height: 20px;
  color: #000000;
  margin: 0 0 4px;
}
.evidencing-table-filter-selections
  .table-filter-selector
  ::v-deep(.v-select__selections p) {
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  margin: 0;
}
.evidencing-table-filter-selections
  .table-filter-selector
  ::v-deep(.v-input__control) {
  min-height: 32px !important;
  height: 32px;
}
.evidencing-table-filter-selections
  .table-filter-selector
  ::v-deep(.v-input__control input) {
  cursor: pointer;
}
.evidencing-table-filter-selections .table-filter-selector ::v-deep(.v-label) {
  font-size: 14px;
  line-height: 20px;
  max-width: 75%;
}
.evidencing-table-filter-selections
  .table-filter-selector
  ::v-deep(.v-input__slot) {
  min-height: 32px;
  height: 32px;
  margin: 0;
  box-shadow: none !important;
}
.evidencing-table-filter-selections
  .table-filter-selector
  ::v-deep(.v-text-field__details),
.evidencing-table-filter-selections
  .fields-search
  ::v-deep(.v-text-field__details) {
  display: none;
}
.table-filter-selector > .v-input {
  border: 2px solid transparent;
}
.table-filter-selector > .v-input.has-items {
  border-color: #67AC5B;
}
.evidencing-action-bar {
  padding: 12px 24px;
  width: 100%;
  background: #464b58;
  border-radius: 8px;
  margin-bottom: 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
.evidencing-action-bar > h2 {
  font-size: 16px;
  line-height: 20px;
  color: #ededed;
  font-weight: bold;
  width: 236px;
  margin: 0 24px 0 0;
}
.evidencing-action-bar > button {
  margin-right: 20px;
}
.highlighted {
  background: rgba(25, 118, 210, 0.1);
}
</style>

<style>
.no-data-found {
  padding: 16px;
}
.no-data-found p {
  color: #000000;
  font-size: 18px;
}
.loading-wrapper {
  padding-top: 64px;
  display: flex;
  justify-content: center;
}
.v-data-table__wrapper {
  overflow: unset;
}
.v-list-item__action {
  margin-right: 12px !important;
}
.map-evidencing-filters {
  position: absolute;
  top: 0;
  width: 100%;
  padding: 0 16px 0 52px;
  background: #FFFFFF;
  z-index: 3;
}
.map-evidencing-filters .evidencing-table-filter-selections {
  padding: 8px 0 12px;
}
.map-evidencing-filters .fields-search {
  align-self: center !important;
  margin-top: 8px;
}
.ellipsized-p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  text-transform: capitalize;
}
.ellipsized-p + input {
  display: none;
}
.v-list-item__title {
  text-transform: capitalize;
}
</style>
