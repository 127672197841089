<template>
  <b-td
    :id="'harvest-table-' + rowNum"
    class="cell-table harvest-col"
    :class="{
      'gray-bg': rowNum % 2 !== 0,
    }"
  >
    <div>
      <b-table-simple class="mb-0" width="100%">
        <b-tbody>
          <HarvestRow
            v-for="harvest in harvestData"
            :addition-view="false"
            :isLocked="isLocked"
            :cropId="cropId"
            :field="fieldId"
            :harvest="harvest"
            :key="harvest.idx"
            :rowIndex="harvestData.indexOf(harvest)"
            :year="year"
            @handle-copy="handleCopyHarvest($event)"
            @bad-input="handleBadInput"
          />
        </b-tbody>
      </b-table-simple>
      <!-- <div
        v-if="!showAddition"
        class="btn btn-primary add-icon icon-column mr-0 pull-right"
        @click="showAddition = true"
      >
        <i class="fa fa-plus" />
      </div> -->
      <div v-if="showAddition" class="border">
        <div class="float-right mr-1 mt-1">
          <i class="fa fa-times-circle fa-2x" @click="resetHarvestToAdd" />
        </div>
        <div class="addition-container">
          <b-table-simple class="mt-4" width="100%">
            <b-tbody>
              <HarvestRow
                :addition-view="true"
                :harvest="harvestToAdd"
                :year="year"
                @bad-input="handleBadInput"
              />
            </b-tbody>
          </b-table-simple>
          <div class="d-flex flex-row justify-content-between my-3">
            <div class="add-text">
              {{ showCopy ? "Copy" : "Add" }} to
              <!-- Re-enable for multifield add/copy -->
              <!-- {{ fieldYearToCopy.length }} -->
              additional years:
            </div>
            <div>
              <v-select-old
                multiple
                class="year-select"
                :options="years"
                v-model="yearSelected"
              />
            </div>
          </div>
          <!-- Re-enable for multifield add/copy -->
          <!-- <div class="d-flex flex-row justify-content-between mt-1">
            <div class="mb-3">
              <div class="d-flex flex-row">
                <span>Years: </span
                ><v-select-old
                  class="ml-2 year-select"
                  multiple
                  :options="years"
                  v-model="yearSelected"
                />
              </div>
            </div>
            <div>
              <v-select-old
                multiple
                class="field-select"
                label="name"
                :options="allFields"
                :reduce="o => o.id"
                v-model="fieldYearToCopy"
              />
            </div>
            <div>
              <button
                type="button"
                class="btn btn-primary py-2"
                @click="handleAddFieldsInFocus"
              >
                Select
                <span class="badge badge-pill badge-success">{{
                  selectedFields.length
                }}</span>
                Fields
              </button>
            </div>
          </div> -->
        </div>
      </div>
      <button
        v-if="showAddition"
        type="button"
        class="btn btn-success btn-lg btn-block"
        @click="handleSubmitHarvest"
      >
        {{ showCopy ? "Copy" : "Submit" }} Harvest <i class="fa fa-plus" />
      </button>
    </div>
  </b-td>
</template>
<script>
import { cloneDeep } from "lodash"
import { mapActions, mapGetters } from "vuex"

import HarvestRow from "@/components/spreadsheet/rows/HarvestRow"
import { Defaults, Filter } from "@/store/modules"

export default {
  name: "HarvestTable",
  components: {
    HarvestRow,
  },
  props: {
    cropId: { type: Number },
    fieldId: { type: String },
    harvestData: { type: Array },
    rowNum: { type: Number },
    year: { type: String },
    years: { type: Array },
    isLocked: { type: Boolean },
  },
  data() {
    return {
      showAddition: false,
      showCopy: false,
      harvestToAdd: {
        crop_id: { source: "User Input", value: null },
        crop_name: { source: "User Input", value: null },
        date: { source: "User Input", value: null },
        harvest_type: { source: "User Input", value: null },
        residue_burned: { source: "User Input", value: null },
        residue_removal: { source: "User Input", value: null },
        unit: { source: "User Input", value: null },
        yield: { source: "User Input", value: null },
      },
      fieldYearToCopy: [],
      yearSelected: [],
    }
  },
  computed: {
    ...mapGetters({
      allFields: Filter.Getters.getFields,
      selectedFields: Filter.Getters.getSelectedFields,
    }),
  },
  methods: {
    ...mapActions({
      updateMultipleFieldSettings: Defaults.Actions.updateMultipleFieldSettings,
    }),
    handleAddFieldsInFocus() {
      this.selectedFields.forEach(field => {
        if (
          !this.fieldYearToCopy.includes(this.fieldId) &&
          field.id !== this.fieldId
        ) {
          this.fieldYearToCopy.push(field.id)
        }
      })
    },
    handleCopyHarvest(harvestToCopy) {
      this.showAddition = true
      this.showCopy = true

      this.harvestToAdd = cloneDeep(harvestToCopy)
    },
    handleSubmitHarvest() {
      // TODO Re-enable for multi-field add/copy
      // if (this.showAddition && !this.showCopy) {
      //   this.fieldYearToCopy.push(this.fieldId)
      // }
      this.fieldYearToCopy.push(this.fieldId)
      this.updateMultipleFieldSettings({
        cropId: this.cropId,
        dataCategory: "harvests",
        fieldIds: this.fieldYearToCopy,
        rowData: this.harvestToAdd,
        years: this.yearSelected,
      })
      this.resetHarvestToAdd()
    },
    resetHarvestToAdd() {
      this.harvestToAdd = {
        crop_id: { source: "User Input", value: null },
        crop_name: { source: "User Input", value: null },
        date: { source: "User Input", value: null },
        harvest_type: { source: "User Input", value: null },
        residue_burned: { source: "User Input", value: null },
        residue_removal: { source: "User Input", value: null },
        unit: { source: "User Input", value: null },
        yield: { source: "User Input", value: null },
      }
      this.showAddition = false
      this.showCopy = false
      this.fieldYearToCopy = []
      this.yearSelected = [this.year]
    },
    handleBadInput(errorText) {
      this.$emit("bad-input", errorText)
    },
  },
  mounted() {
    if (this.year !== null) this.yearSelected.push(this.year)
  },
}
</script>
<style scoped>
.harvest-bg {
  background-color: #fcf1c5;
  /* background-color: #f7dd72; */
}

.harvest-bg-dark {
  background-color: #f7dd72;
  /* background-color: #f2c618; */
}

.gray-bg {
  background-color: #f6f7fb !important;
}

.addition-container {
  padding-left: 40px;
  padding-right: 84px;
}

.add-icon {
  background-color: #7774e7;
  color: white;
  cursor: pointer;
  padding-left: 14px;
}

.add-icon:hover,
.add-icon:focus {
  color: #ffffff;
  background-color: #8c8aeb;
}

.add-text {
  font-weight: 500;
  font-size: 14px;
}

.cell-table {
  padding: 0;
}

.complete {
  background-color: #00a200;
}

.arva-generated {
  background-color: #fedb80;
}

.delete {
  color: #cc0101;
}

/* v-select-old widths */
.field-select {
  width: 480px;
}

/* table-width */
.liming-col {
  width: 1132px;
}
</style>
