<template>
  <ArvaModal
    name="dataImportModal"
    :title="`Manage File Aggregation for ${fieldsToEdit.length} Selected Fields`"
    tooltip="You can manage the activation or deactivation of geospatial layers for a selected year. These layers contribute to the aggregation process and support further analysis. Please ensure that only the necessary layers are active for the specified year."
    @close-modal="closeModal"
  >
    <v-card>
      <div v-if="isProcessing" class="spinner-container">
        <v-progress-circular indeterminate color="primary" size="30" />
        <p>Processing your request...</p>
      </div>

      <div v-else>
        <v-card-title>
          <span class="text-h6">Select Year and Layers</span>
        </v-card-title>
        <v-card-text>
          <v-select
            v-model="selectedYear"
            :items="years"
            label="Select Year"
            outlined
          ></v-select>

          <v-checkbox
            v-model="selectedLayers"
            label="Planting"
            value="Planting"
            hide-details
          ></v-checkbox>
          <v-checkbox
            v-model="selectedLayers"
            label="Applied"
            value="Applied"
            hide-details
          ></v-checkbox>
          <v-checkbox
            v-model="selectedLayers"
            label="Harvest"
            value="Harvest"
            hide-details
          ></v-checkbox>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="!isFormValid"
            color="success"
            @click="activateLayers"
            >Activate</v-btn
          >
          <v-btn
            :disabled="!isFormValid"
            color="error"
            @click="deactivateLayers"
            >Deactivate</v-btn
          >
          <v-btn color="secondary" @click="closeModal">Close</v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </ArvaModal>
</template>

<script>
import ArvaModal from "./ArvaModal"

export default {
  name: "ManageFileAggregationModal",
  props: ["years", "fieldsToEdit"],
  components: { ArvaModal },
  data() {
    return {
      selectedYear: null,
      selectedLayers: [],
      isProcessing: false,
    }
  },
  computed: {
    isFormValid() {
      return this.selectedYear && this.selectedLayers.length > 0
    },
  },
  methods: {
    closeModal() {
      this.$emit("close-modal")
    },
    activateLayers() {
      const payload = {
        year: this.selectedYear,
        layers: this.selectedLayers,
        action: "activate",
      }
      this.$emit("manage-layers", payload)
      this.isProcessing = true
    },
    deactivateLayers() {
      const payload = {
        year: this.selectedYear,
        layers: this.selectedLayers,
        action: "deactivate",
      }
      this.$emit("manage-layers", payload)
      this.isProcessing = true
    },
  },
}
</script>
<style scoped>
.spinner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
}

.spinner-container p {
  margin-top: 10px;
  font-size: 16px;
  color: #555;
}
</style>
