<template>
  <b-tr>
    <!-- date -->
    <b-td
      class="cell-table medium-column"
      :class="getClassForCell(liming.date)"
    >
      <v-menu
        :disabled="isLocked"
        v-model="showDate"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <div class="d-flex">
            <div class="date-holder">
              <v-text-field
                :disabled="isLocked"
                v-model="date"
                dense
                readonly
                hide-details="true"
                v-bind="attrs"
                v-on="on"
                :append-icon="getAppendIconForCell(liming.date)"
                @click:append="handleClickSubmit('date', date)"
              ></v-text-field>
            </div>
            <!-- <div class="cell-text date-button">
              <b-iconstack
                v-if="
                  liming.date.source !== 'User Input' &&
                    liming.date.value !== null
                "
                class="input-check"
                font-scale="1.75"
                @click="handleClickSubmit('date', date)"
              >
                <b-icon stacked class="bg-white" icon="circle-fill"></b-icon>
                <b-icon
                  stacked
                  class="submit-icon"
                  icon="check-circle-fill"
                ></b-icon>
                <b-icon stacked icon="circle"></b-icon>
              </b-iconstack>
            </div> -->
          </div>
        </template>
        <v-date-picker
          :disabled="isLocked"
          v-model="date"
          :min="yearPriorOne + '-06-01'"
          :max="year + '-12-31'"
          @input="showDate = false"
          scrollable
        ></v-date-picker>
      </v-menu>
    </b-td>
    <!-- liming type -->
    <b-td class="cell-table large-column" :class="getClassForCell(liming.type)">
      <PracticeChoiceDropdown
        :disabled="isLocked"
        clearable
        dense
        hide-details="true"
        :items="limingTypeChoices"
        item-text="display_name"
        item-value="value"
        v-model="type"
        choiceName="liming_type"
        :append-icon="getAppendIconForCell(liming.type)"
        @click:append="handleClickSubmit('type', type)"
      />
    </b-td>
    <!-- liming amount -->
    <b-td
      class="cell-table medium-column"
      :class="{
        'amount-column': !additionView,
        'amount-column-add': additionView,
        ...getClassForCell(liming.amount),
      }"
    >
      <v-text-field
        :disabled="isLocked"
        clearable
        dense
        v-model="amount"
        hide-details="true"
        suffix="tons"
        :append-icon="getAppendIconForCell(liming.amount)"
        @keypress="filter($event)"
        @click:append="handleClickSubmit('amount', amount)"
      >
      </v-text-field>
      <!-- <div class="d-flex cell-text">
        <input
          v-model="amount"
          type="number"
          class="border rounded px-2 mr-1 text-right amount-input focus:outline-none focus:border-indigo-300"
        />tons
        <b-iconstack
          v-if="
            liming.amount.source !== 'User Input' &&
              liming.amount.value !== null
          "
          class="ml-auto input-check"
          font-scale="1.75"
          @click="handleClickSubmit('amount', amount)"
        >
          <b-icon stacked class="bg-white" icon="circle-fill"></b-icon>
          <b-icon stacked class="submit-icon" icon="check-circle-fill"></b-icon>
          <b-icon stacked icon="circle"></b-icon>
        </b-iconstack>
      </div> -->
    </b-td>
    <b-td class="cell-table black">
      <v-btn :disabled="isLocked" icon x-small color="white" @click="handleAdd">
        <v-icon>mdi-plus-circle-outline</v-icon>
      </v-btn>
      <v-btn
        :disabled="isLocked"
        icon
        x-small
        color="white"
        @click="handleDelete"
      >
        <v-icon>mdi-close-circle-outline</v-icon>
      </v-btn>
    </b-td>
  </b-tr>
</template>
<script>
import { mapMutations, mapGetters } from "vuex"
import { Defaults } from "@/store/modules"
import { getAppendIconForCell, getClassForCell } from "../utils"
import PracticeChoiceDropdown from "@/components/dropdowns/PracticeChoiceDropdown"

export default {
  name: "LimingRow",
  props: {
    additionView: { type: Boolean, default: false },
    cropId: { type: Number },
    field: { type: String },
    liming: { type: Object },
    rowIndex: { type: Number },
    year: { type: String },
    isLocked: { type: Boolean },
  },
  components: {
    PracticeChoiceDropdown,
  },
  data() {
    return {
      showDate: false,
      getClassForCell,
      getAppendIconForCell,
    }
  },
  computed: {
    ...mapGetters({
      practiceChoices: Defaults.Getters.getPracticeChoices,
    }),
    yearPriorOne() {
      return String(Number(this.year) - 1)
    },
    limingTypeChoices() {
      return this.practiceChoices["liming_type"]
    },
    amount: {
      get() {
        return this.liming.amount.value
      },
      set(val) {
        if (val === "") {
          val = null
        }
        let validVal = this.handleValidateValue(val, "number")
        if (validVal) {
          const updatePayload = {
            fieldId: this.field,
            year: this.year,
            crop: this.cropId,
            dataCategory: "limings",
            dataSetting: "amount",
            value: val,
            rowIndex: this.rowIndex,
          }
          this.spreadsheetMutate(updatePayload)
        }
      },
    },
    date: {
      get() {
        const dateReturn = this.liming.date.value
          ? this.liming.date.value
          : `${this.year}-01-01`
        return dateReturn
      },
      set(val) {
        const updatePayload = {
          fieldId: this.field,
          year: this.year,
          crop: this.cropId,
          dataCategory: "limings",
          dataSetting: "date",
          value: val,
          rowIndex: this.rowIndex,
        }
        this.spreadsheetMutate(updatePayload)
      },
    },
    type: {
      get() {
        return this.liming.type.value
      },
      set(val) {
        const updatePayload = {
          fieldId: this.field,
          year: this.year,
          crop: this.cropId,
          dataCategory: "limings",
          dataSetting: "type",
          value: val,
          rowIndex: this.rowIndex,
        }
        this.spreadsheetMutate(updatePayload)
      },
    },
  },
  methods: {
    ...mapMutations({
      addFieldSettingRow: Defaults.Mutations.addFieldSettingRow,
      deleteNewFieldSetting: Defaults.Mutations.deleteNewFieldSetting,
      updateNewFieldSetting: Defaults.Mutations.updateNewFieldSetting,
      spreadsheetMutate: Defaults.Mutations.spreadsheetMutate,
    }),
    handleClickSubmit(setting, val) {
      this[setting] = val
    },
    handleCopy() {
      this.$emit("handle-copy", this.liming)
    },
    handleAdd() {
      const payloadObj = {
        amount: { source: "User Input", value: null },
        crop_id: { source: "User Input", value: this.cropId },
        date: { source: "User Input", value: null },
        type: { source: "User Input", value: null },
      }
      this.addFieldSettingRow({
        fieldId: this.field,
        cropId: this.cropId,
        dataCategory: "limings",
        dataObj: payloadObj,
        year: this.year,
      })
    },
    handleDelete() {
      var defaultObj = {}
      for (const [key, value] of Object.entries(this.liming)) {
        if (key === "crop_id") defaultObj[key] = value
        else defaultObj[key] = { source: "User Input", value: null }
      }

      this.$emit("handle-delete", this.liming)
      this.deleteNewFieldSetting({
        fieldId: this.field,
        cropId: this.cropId,
        dataCategory: "limings",
        defaultObj: defaultObj,
        rowIndex: this.rowIndex,
        year: this.year,
      })
    },
    handleValidateValue(val, type, length = 24) {
      function isNumeric(str) {
        if (typeof str != "string") return false // we only process strings!
        return (
          !isNaN(str) && !isNaN(parseFloat(str)) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        ) // ...and ensure strings of whitespace fail
      }

      if (val === null) {
        return true
      }

      if (val.length > length) {
        this.$emit(
          "bad-input",
          "Inputted value length was too long (24 characters allowed)."
        )
        return false
      }

      if (type == "number") {
        if (isNumeric(val)) {
          return true
        } else {
          this.$emit(
            "bad-input",
            "Look out! A numerical value is expected here."
          )
          return false
        }
      } else if (type == "string") {
        return true
      }
    },
    filter(event) {
      event = event ? event : window.event
      let expect = event.target.value.toString() + event.key.toString()

      let validVal = this.handleValidateValue(expect, "number", 24)
      if (!validVal) event.preventDefault()
    },
  },
}
</script>
<style scoped>
.arva-alert {
  background-color: #f7c0be;
}

.cell-table {
  /* padding: 0; */
  padding: 2px 6px;
}

.pre-gen {
  background-color: #fedb80;
}

.bg-white {
  color: white;
}

.cell-text {
  font-weight: 600;
  color: white;
}

.machine {
  background-color: #c0dce2;
}

.complete {
  background-color: #c7e3c1;
}

.copy-icon {
  cursor: pointer;
  color: #0f9aee;
}

.input-check {
  cursor: pointer;
}

.select-check {
  margin-top: 14px;
  cursor: pointer;
}

.submit-icon {
  color: #c7e3c1;
}

.delete {
  color: #cc0101;
  cursor: pointer;
}

.medium-column {
  width: 140px;
}

.large-column {
  width: 240px;
}

.marge-column {
  width: 180px;
}

.v-input {
  font-size: 18px;
  /* font-weight: bold; */
}
</style>
