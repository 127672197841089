import Vue from 'vue'
import { EnrollmentGroups } from "@/store/modules"
import {
  getRollup,
  listOverview,
  getFssMetadata,
  submitSupplyRequests,
  applyPendingSupplyStates,
  submitEvidencingLink
} from "@/api/ContractGroupAPI"
import { 
  NO,
  YES,
  CP_VIEW,
  REQUESTED,
  CONFIRMED,
  ROLLUP_TABLE,
  FSS_INDIVIUAL,
  REQUESTED_PENDING,
  ENROLLMENT_TABLE,
  BLANK_METADATA,
  BLANK_ACREAGE_VALUES
} from "@/constants/contractGroups"
import { CROP_DISPLAY_NAME, US_STATES } from "@/constants"


const parseGroupData = (groupData, rollupData) => {
  const parsedData = {}

  for (const [groupId, { name, timestamps, snapshot, fieldsupply, evidencing_links }] of Object.entries(groupData)) {
    const acreageValues = JSON.parse(JSON.stringify(BLANK_ACREAGE_VALUES))
    const filteredFss = []
    const cropRegionMap = {}
    const supplyChanges = {}
    supplyChanges[YES] = []
    supplyChanges[NO] = []

    fieldsupply
    .map(fss => {
      return {
        ...fss,
        harvest_type: fss['harvest_type'] == 'Silage' ? 'Silage' : 'Normal',
        metadata: fss['metadata'] == null ? { ...BLANK_METADATA } : fss['metadata']
      }
    })
    .filter(({ crop_id, harvest_type }) => {
      const found = rollupData.find(rollup => rollup['crop_ids'].includes(crop_id) && rollup['harvest_type'] == harvest_type)
      return found != null
    })
    .forEach(fss => {
      const { supply_state, id, status, harvest_type, state_name, crop_id, acreage, metadata=null } = fss

      if (state_name == null) return

      filteredFss.push({ ...fss })

      const safeHarvestType = harvest_type == 'Silage' ? 'Silage' : 'Normal'

      if (!cropRegionMap.hasOwnProperty(crop_id)) {
        cropRegionMap[crop_id] = { regions: [], harvest_type: safeHarvestType }
        acreageValues[CONFIRMED][crop_id] = { 'Normal': {}, 'Silage': {} }
        acreageValues[REQUESTED][crop_id] = { 'Normal': {}, 'Silage': {} }
        acreageValues[REQUESTED_PENDING][crop_id] = { 'Normal': {}, 'Silage': {} }
      }
      if (!cropRegionMap[crop_id]['regions'].includes(state_name)) {
        cropRegionMap[crop_id]['regions'].push(state_name)
        acreageValues[CONFIRMED][crop_id]['Normal'][state_name] = { acreage: 0, numFields: 0 }
        acreageValues[REQUESTED][crop_id]['Normal'][state_name] = { acreage: 0, numFields: 0 }
        acreageValues[REQUESTED_PENDING][crop_id]['Normal'][state_name] = { acreage: 0, numFields: 0 }
        acreageValues[CONFIRMED][crop_id]['Silage'][state_name] = { acreage: 0, numFields: 0 }
        acreageValues[REQUESTED][crop_id]['Silage'][state_name] = { acreage: 0, numFields: 0 }
        acreageValues[REQUESTED_PENDING][crop_id]['Silage'][state_name] = { acreage: 0, numFields: 0 }
      }

      const otherState = supply_state == YES ? NO : YES

      // everything w/o a snapshot is a 'change'
      // if a snapshot exists, 'yes' group supply in a 'no' snapshot supply is a 'change'
      // i.e. a group supply change has happened that hasn't been saved to a snapshot yet
      if (snapshot == null) {
        supplyChanges[supply_state].push(id)
      }
      else {
        if (snapshot[`${otherState}_fieldsupply`].includes(id)) {
          // group supply 'yes', snapshot 'no', for example, is a change to 'yes'
          // that hasn't been realized yet
          supplyChanges[supply_state].push(id)
        }
        else if (!snapshot[`${supply_state}_fieldsupply`].includes(id)) {
          // fss isn't in any of the snapshot supply, so we must have submitted a partial crop-only snapshot
          // so this would count as a supply change since it's in the group but not the snapshot
          supplyChanges[supply_state].push(id)
        }
      }

      // NOTE: the below steps should amalgamate into the above steps
      //
      // supply can be counted for both requested/confirmed AND for pending changes
      // i.e. is in a requested state + is pending request removal
      if (status == REQUESTED) {
        acreageValues[REQUESTED][crop_id][safeHarvestType][state_name]['acreage'] += acreage
        acreageValues[REQUESTED][crop_id][safeHarvestType][state_name]['numFields'] += 1
      }
      if (status == CONFIRMED) {
        acreageValues[CONFIRMED][crop_id][safeHarvestType][state_name]['acreage'] += acreage
        acreageValues[CONFIRMED][crop_id][safeHarvestType][state_name]['numFields'] += 1
      }

      for (const supplyState of [YES, NO]) {
        if (supplyChanges[supplyState].includes(id)) {
          if (supplyState == YES) {
            acreageValues[REQUESTED_PENDING][crop_id][safeHarvestType][state_name]['acreage'] += acreage
            acreageValues[REQUESTED_PENDING][crop_id][safeHarvestType][state_name]['numFields'] += 1
          }
          if (supplyState == NO) {
            if (snapshot != null && snapshot['yes_fieldsupply'].includes(id)) {
              // check if there's a previous snapshot. If there's no snapshot, don't count
              // the 'no's, as you can't subtract from 0 here.
              // Also, because we are allowing partial snapshots, we need to confirm that this fss Id exists
              // in the opposite 'yes' field supply in the snapshot. If it doesn't, we shouldn't count it
              // as it hasn't been submitted at all yet.
              acreageValues[REQUESTED_PENDING][crop_id][safeHarvestType][state_name]['acreage'] -= acreage
              acreageValues[REQUESTED_PENDING][crop_id][safeHarvestType][state_name]['numFields'] += 1
            }
          }
        }
      }
    })

    const crops = Object.keys(cropRegionMap).map(c => ({
      id: Number(c),
      harvest_type: cropRegionMap[c]['harvest_type']
    }))
    const regions = Object.values(cropRegionMap).reduce((acc, curr) => {
      for (const region of curr['regions']) {
        if (!acc.includes(region)) acc.push(region)
      }

      return acc
    }, [])

    parsedData[groupId] = {
      name,
      crops,
      regions,
      cropRegionMap,
      snapshot,
      timestamps,
      evidencing_links,
      fieldsupply: filteredFss,
      supplyChanges,
      acreageValues
    }
  }

  return parsedData
}

const tableLoadingState = {}
tableLoadingState[ENROLLMENT_TABLE] = { loading: true, error: null }
tableLoadingState[ROLLUP_TABLE] = { loading: true, error: null }
tableLoadingState[FSS_INDIVIUAL] = []

const state = {
  ...tableLoadingState,
  groupData: {},
  rollupData: [],
  view: CP_VIEW,
  org: null
}

const getters = {
  [EnrollmentGroups.Getters.allUniqueRegions]: state => {
    return Object.values(state.groupData)
      .reduce((accum, { regions }) => {
        for (const region of regions) {
          const found = US_STATES.find(({ text }) => text == region)
          if (found == null && !accum.includes('Canada')) accum.push('Canada')
          if (found != null && !accum.includes(found['value'])) accum.push(found['value'])
        }
        
        return accum
      }, [])
      .sort()
  },
  [EnrollmentGroups.Getters.allUniqueCrops]: state => {
    return Object.values(state.groupData)
    .reduce((accum, { crops }) => {
      for (const { id, harvest_type } of crops) {
        const found = accum.find(c => c['id'] == id)
        
          // look what they did to my boy
        if (found == null) accum.push({ id, harvest_type: null, itemKey: `${id}-Normal` })
        else if (
          found['id'] == 4
          && accum.find(c => c['id'] == 4 && c['harvest_type'] == 'Silage') == null
          && harvest_type == 'Silage'
        ) {
          accum.push({ id, harvest_type, itemKey: `${id}-${'Silage'}` })
        }
      }

      return accum
    }, [])
    .sort((a, b) => CROP_DISPLAY_NAME[a['id']].localeCompare(CROP_DISPLAY_NAME[b['id']]))
  }
}

const mutations = {
  [EnrollmentGroups.Mutations.setOrg](state, orgId) {
    state.org = orgId
  },
  [EnrollmentGroups.Mutations.setView](state, view) {
    state.view = view
  },
  [EnrollmentGroups.Mutations.setRollupData](state, rollupData) {
    Vue.set(state, 'rollupData', rollupData)
  },
  [EnrollmentGroups.Mutations.setAllGroupData](state, groupData) {
    state.groupData = Object.freeze(groupData)
  },
  [EnrollmentGroups.Mutations.setFssLoading](state, { fssIds, loading }) {
    const newLoading = [...state[FSS_INDIVIUAL]]
    
    if (loading) {
      for (const fssId of fssIds) 
        if (!newLoading.includes(fssId)) newLoading.push(fssId)
    }
    else {
      for (const fssId of fssIds)
        if (newLoading.includes(fssId)) newLoading.splice(newLoading.indexOf(fssId), 1)
    }

    state[FSS_INDIVIUAL] = Object.freeze(newLoading)
  },
  [EnrollmentGroups.Mutations.setFssMetadata](state, { groupId, data }) {
    const newState = { ...state.groupData }
    const newGroupData = newState[groupId]
    
    for (const [fssId, practices, farmName, thumbnail] of data) {
      newGroupData['fieldsupply'].find(({ id }) => id == fssId)['metadata'] = {
        practices,
        farmName,
        thumbnail
      }
    }

    state.groupData = Object.freeze(newState)
  },
  [EnrollmentGroups.Mutations.setIndividualGroupData](state, groupData) {
    state.groupData = Object.freeze({ ...state.groupData, ...groupData })
  },
  [EnrollmentGroups.Mutations.setGroupSupplyChanges](state, { groupId, supplyState, fssIds }) {
    const idx = state.groupData.findIndex(g => g['id'] == groupId)
    if (idx != -1) {
      Vue.set(state.groupData[idx]['supplyChanges'], supplyState, fssIds)
    }
  },
  [EnrollmentGroups.Mutations.setTableLoadingState](state, { target, loading, error=null }) {
    Vue.set(state, target, { loading, error })
  },
}

const actions = {
  [EnrollmentGroups.Actions.listOverview]({ state, commit, dispatch, rootState }) {
    return new Promise((resolve, reject) => {
      const { year, organization } = rootState.Organization
      if (year == null || organization == null || !('id' in organization)) {
        reject(`Organization or year is null: ${organization}, ${year}`)
        return
      }
  
      commit(EnrollmentGroups.Mutations.setTableLoadingState, { target: ENROLLMENT_TABLE, loading: true });
      listOverview({ org_node_id: organization.id, year })
      .then(({ data }) => {
        commit(EnrollmentGroups.Mutations.setOrg, organization.id)
        commit(EnrollmentGroups.Mutations.setAllGroupData, parseGroupData(data, state.rollupData));
        commit(EnrollmentGroups.Mutations.setTableLoadingState, { target: ENROLLMENT_TABLE, loading: false });
        resolve()
      })
      .catch(error => {
        console.log("Error fetching group data overview: ", error)
        commit(EnrollmentGroups.Mutations.setTableLoadingState, { target: ENROLLMENT_TABLE, loading: false, error });
        reject(error)
      })
    })
  },
  [EnrollmentGroups.Actions.fetchFssMetadata]({ commit, state, rootState }, { groupId }) {
    return new Promise((resolve, reject) => {
      const { year, organization } = rootState.Organization
      if (year == null || organization == null || !('id' in organization)) {
        reject(`Organization or year is null: ${organization}, ${year}`)
        return
      }

      const fssIds = state.groupData[groupId].fieldsupply.map(({ id }) => id)
      commit(EnrollmentGroups.Mutations.setTableLoadingState, { target: ENROLLMENT_TABLE, loading: true });

      getFssMetadata({ fss_ids: fssIds, org_node_id: organization.id, year })
      .then(({ data }) => {
        commit(EnrollmentGroups.Mutations.setFssMetadata, { groupId, data })
        commit(EnrollmentGroups.Mutations.setTableLoadingState, { target: ENROLLMENT_TABLE, loading: false });
        resolve()
      })
      .catch(error => {
        console.log("Error fetching group metadata: ", error)
        commit(EnrollmentGroups.Mutations.setTableLoadingState, { target: ENROLLMENT_TABLE, loading: false, error });
        reject(error)
      })
    })
  },
  [EnrollmentGroups.Actions.fetchRollup]({ commit, rootState, dispatch }, { fetchOverview }) {
    return new Promise((resolve, reject) => {
      const { year, organization } = rootState.Organization
      if (year == null || organization == null || !('id' in organization)) {
        reject(`Organization or year is null: ${organization}, ${year}`)
        return
      }

      if (fetchOverview) {
        commit(EnrollmentGroups.Mutations.setTableLoadingState, { target: ENROLLMENT_TABLE, loading: true });
      }

      commit(EnrollmentGroups.Mutations.setTableLoadingState, { target: ROLLUP_TABLE, loading: true });
      getRollup({ org_node_id: organization.id, year })
      .then(({ data }) => {
        commit(EnrollmentGroups.Mutations.setRollupData, data);
        commit(EnrollmentGroups.Mutations.setTableLoadingState, { target: ROLLUP_TABLE, loading: false });

        if (!fetchOverview) {
          resolve()
        }
        else {
          dispatch(EnrollmentGroups.Actions.listOverview)
          .then(() => resolve())
        }
      })
      .catch(error => {
        console.log("Error fetching program rollup: ", error)
        commit(EnrollmentGroups.Mutations.setTableLoadingState, { target: ROLLUP_TABLE, loading: false, error });
        reject(error)
      })
    })
  },
  [EnrollmentGroups.Actions.submitSupplyChanges]({ commit, dispatch, state }, { groupId=null, groupCrops=null, crop_specific_snapshot=false }) {
    const parsedIds = []

    if (groupId != null) {
      if (groupCrops != null) parsedIds.push(...groupCrops.map(c => [groupId, c]))
      else parsedIds.push([groupId, null])
    }
    
    commit(EnrollmentGroups.Mutations.setTableLoadingState, { target: ENROLLMENT_TABLE, loading: true });
    
    Promise.all(
      parsedIds.map(([group_id, cropId]) => {
        return new Promise((resolve, reject) => {
          const groupData = state.groupData[group_id]

          const getFss = (fssId) => groupData['fieldsupply'].find(fss =>
            fssId == fss['id'] && (cropId == null || cropId == fss['crop_id'])
          )

          submitSupplyRequests({
            group_id,
            crop_specific_snapshot,
            fss_to_request: groupData['supplyChanges'][YES].filter(getFss),
            fss_to_available: groupData['supplyChanges'][NO].filter(getFss),
          })
          .then(({ data }) => {
            const toParse = {}
            toParse[group_id] = data

            // include the old fss metadata
            for (const fss of toParse[group_id]['fieldsupply']) {
              const found = groupData['fieldsupply'].find(f => f['id'] == fss['id'])
              if (found != null && found.hasOwnProperty('metadata')) {
                fss['metadata'] = found['metadata']
              }
            }

            const parsedData = parseGroupData(toParse, state.rollupData)
            commit(EnrollmentGroups.Mutations.setIndividualGroupData, parsedData)
            commit(EnrollmentGroups.Mutations.setTableLoadingState, { target: ENROLLMENT_TABLE, loading: false });
            resolve()
          })
          .catch(error => {
            console.log("Error fetching group data after supply submission", error)
            commit(EnrollmentGroups.Mutations.setTableLoadingState, { target: ENROLLMENT_TABLE, loading: false, error });
            reject(error)
          })
        })
      })
    )
    .then(_ => dispatch(EnrollmentGroups.Actions.fetchRollup, { fetchOverview: false }))
  },
  [EnrollmentGroups.Actions.applyPendingFssState]({ commit, state }, { groupId, fssIds, supplyState }) {
    commit(EnrollmentGroups.Mutations.setFssLoading, { fssIds, loading: true })

    applyPendingSupplyStates({ fss_ids: fssIds, group_id: groupId, supplyState })
    .then(({ data }) => {
      const newGroupData = {}
      newGroupData[groupId] = { ...state.groupData[groupId] }
      newGroupData[groupId]['supplyChanges'][NO] = []
      newGroupData[groupId]['supplyChanges'][YES] = []

      for (const fss of newGroupData[groupId]['fieldsupply']) {
        const otherState = fss['supply_state'] == YES ? NO : YES

        if (data[`${otherState}_fieldsupply`].includes(fss['id'])) {
          fss['supply_state'] = otherState
        }
      }

      commit(EnrollmentGroups.Mutations.setIndividualGroupData, parseGroupData(newGroupData, state.rollupData))
      commit(EnrollmentGroups.Mutations.setFssLoading, { fssIds, loading: false })
    })
  },
  [EnrollmentGroups.Actions.submitEvidencingLink]({ commit, state }, { group_id, updated_link_obj }) {
    commit(EnrollmentGroups.Mutations.setTableLoadingState, { target: ENROLLMENT_TABLE, loading: true });

    submitEvidencingLink({ group_id, updated_link_obj })
    .then(() => {
      const newGroupData = {}
      newGroupData[group_id] = { ...state.groupData[group_id], evidencing_links: updated_link_obj }
      commit(EnrollmentGroups.Mutations.setIndividualGroupData, newGroupData)

      commit(EnrollmentGroups.Mutations.setTableLoadingState, { target: ENROLLMENT_TABLE, loading: false });
    })
    .catch(error => {
      commit(EnrollmentGroups.Mutations.setTableLoadingState, { target: ENROLLMENT_TABLE, loading: false, error });
    })
  }
}

export default {
    state,
    mutations,
    getters,
    actions
  }
