<template>
  <ArvaModal
    name="editFieldAssignmentModal"
    title="Edit Field Assignment"
    :wide="true"
    :width="1100"
    @close-modal="handleCloseModal"
  >
    <v-card>
      <div>
        <v-card class="pa-2" v-if="tabTitles">
          <v-toolbar>
            <v-chip color="green lighten-1 mb-2" v-if="updateOrderID">
              Subprogram: {{ updateOrderName }} - ({{ updateOrderID }})
            </v-chip>
            <v-spacer />
            <v-chip
              class="ml-5 py-6 float-right"
              v-if="insetOrderRollupName"
              color="orange lighten-1 mb-2"
            >
              Program Rollup: {{ insetOrderRollupName }} (Avg Co2e:
              {{ insetOrderRollupCO2e }}) <br />
              Acres Allocated vs Alloted: {{ insetOrderRollupAcreSummary }}
            </v-chip>

            <template v-slot:extension>
              <v-tabs grow v-model="tab">
                <v-tabs-slider color="blue"></v-tabs-slider>
                <v-tab v-for="item in tabTitles" :key="item.index">
                  {{ item.name }}
                </v-tab>
              </v-tabs>
            </template>
          </v-toolbar>
          <v-card class="pt-2">
            <v-tabs-items v-model="tab">
              <v-tab-item v-for="item in tabTitles" :key="item.index">
                <v-card flat v-if="item.index == 0">
                  <v-text-field
                    v-model="includedSearch"
                    label="Search"
                    prepend-inner-icon="mdi-magnify"
                    variant="outlined"
                    hide-details
                    single-line
                  ></v-text-field>
                  <v-data-table
                    v-model="selectedToRemove"
                    :headers="supplyHeaders"
                    :items="fieldSupply"
                    :search="includedSearch"
                    :single-select="false"
                    show-select
                    item-key="fss_id"
                    :items-per-page="200"
                    height="40vh"
                    fixed-header
                    fixed-footer
                  ></v-data-table>
                  <v-btn
                    color="primary"
                    v-if="selectedToRemove.length > 0"
                    @click="removeFields"
                    >Remove Selected {{ selectedToRemove.length }} Field(s) ({{
                      toRemoveAcreage.toFixed(1)
                    }}
                    ac) From Program</v-btn
                  >
                </v-card>
                <v-card flat v-if="item.index == 1">
                  <!--<h4>Requested Order Filters:</h4>-->
                  <v-text-field
                    v-model="eligibleSearch"
                    label="Search"
                    prepend-inner-icon="mdi-magnify"
                    variant="outlined"
                    hide-details
                    single-line
                  ></v-text-field>
                  <v-data-table
                    v-model="selectedToInclude"
                    :headers="supplyHeaders"
                    :items="fieldSupplyEligible"
                    :search="eligibleSearch"
                    show-select
                    :items-per-page="200"
                    height="40vh"
                    fixed-header
                    fixed-footer
                    item-key="fss_id"
                  ></v-data-table>
                  <v-btn
                    color="primary"
                    v-if="selectedToInclude.length > 0"
                    @click="addFields"
                    >Add Selected {{ selectedToInclude.length }} Field(s) ({{
                      toAddAcreage.toFixed(1)
                    }}
                    ac) to Program
                  </v-btn>
                </v-card>
                <v-card flat v-if="item.index == 2">
                  <v-data-table
                    v-model="summaryDataArray"
                    :headers="supplySummaryHeaders"
                    :items="summaryDataArray"
                    item-key="channelPartner"
                    fixed-header
                    :items-per-page="200"
                    height="40vh"
                    fixed-footer
                  ></v-data-table>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card>

          <div
            v-if="showLoading"
            class="mt-3 ml-6 d-flex justify-content-center"
          >
            <div class="text-bold mb-0">Fetching Data</div>
            <div class="spinner-border ml-4" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <div v-else class="pa-4">
            <h5 v-if="fieldSupplyEligible.length < 1 && fieldSupply.length < 1">
              <b>No Fields Currently Available to Add or Remove</b>
            </h5>
          </div>
        </v-card>

        <ArvaModalSaveFooter
          confirmText="Return"
          cancelText="Cancel"
          @on-cancel="handleCloseModal"
          @on-confirm="handleSubmit"
        />
      </div>
    </v-card>
  </ArvaModal>
</template>

<script>
import ArvaModal from "@/components/modals/ArvaModal"
import ArvaModalSaveFooter from "@/components/modals/ArvaModalSaveFooter"
import CarbonReadyAPI from "@/api/CarbonReadyAPI"

export default {
  name: "EditFieldAssignmentModal",
  props: ["updateOrderID", "updateOrderName"],
  components: { ArvaModal, ArvaModalSaveFooter },

  data() {
    return {
      tab: 0,

      previewDataFrame: null,
      orderName: null,
      showError: false,
      orderPrice: 15,
      orderGeoids: [],
      supplyTarget: 10000,
      errorText: "Please Provide an Unique Order Name",
      aggregationMetric: "acreage",
      aggregationMetrics: ["acreage", "harvest_amount", "scope3_emission"],
      fieldSupplyEligible: [],
      fieldSupply: [],
      selectedToRemove: [],
      selectedToInclude: [],
      includedSearch: "",
      eligibleSearch: "",
      totalAcreageInProgram: 0,
      totalAcreageEligible: 0,
      showLoading: false,
      insetOrderRollupCO2e: null,
      insetOrderRollupName: null,
      summaryDataArray: [],
      insetOrderRollupAcreSummary: "",
      supplySummaryHeaders: [
        { text: "Channel Partner", value: "channelPartner" },
        { text: "Total Fields", value: "num_fields" },
        { text: "Total Acreage", value: "acreage" },
      ],
      supplyHeaders: [
        { text: "Field ID", value: "field_id" },
        { text: "Field", value: "field" },
        { text: "Client", value: "client" },
        { text: "Channel Partner", value: "channel_partner" },
        { text: "Status", value: "status" },
        { text: "Acreage", value: "acreage" },
        { text: "CO2e/ac", value: "average_co2e" },
        { text: "Crop", value: "crop" },
        //{ text: "Enroll ID", value: "enrollment_id" },
      ],
    }
  },

  computed: {
    toAddAcreage() {
      return this.selectedToInclude
        .map(e => e.acreage)
        .reduce((a, b) => a + b, 0)
    },
    toRemoveAcreage() {
      return this.selectedToRemove
        .map(e => e.acreage)
        .reduce((a, b) => a + b, 0)
    },
    tabTitles() {
      let tabNames = []
      if (this.fieldSupply.length > 0) {
        tabNames.push({
          index: 0,
          name:
            "Allocated Fields" +
            " (" +
            this.totalAcreageInProgram.toFixed(2) +
            "ac" +
            ")",
        })
      }
      if (this.fieldSupplyEligible.length > 0) {
        tabNames.push({
          index: 1,
          name:
            "Eligible Fields" +
            " (" +
            (this.totalAcreageEligible.toFixed(2) + "ac" + ")"),
        })
      }

      if (this.fieldSupply.length > 0 || this.fieldSupplyEligible.length > 0) {
        tabNames.push({ index: 2, name: "Supply Summary" })
      }
      return tabNames
    },
  },

  methods: {
    handleCloseModal() {
      this.$emit("close-modal")
    },
    handleSubmit() {
      this.$emit("close-modal")
    },

    removeFields() {
      let payload = {
        fieldSupplyIds: this.selectedToRemove.map(e => e.fss_id),
        order_id: this.updateOrderID,
        job_type: "remove",
      }

      CarbonReadyAPI.postEAOrderFSS(payload)
        .then(response => {
          if (response.status == 200) {
            this.refreshFSSList()
            this.selectedToRemove = []
            //this.$emit("save-from-modal")
          } else {
            this.errorText = response.data
            this.showError = true
          }
        })
        .catch(err => {
          this.errorText = err.response.data
          this.showError = true
        })
    },
    addFields() {
      this.showError = false
      let payload = {
        fieldSupplyIds: this.selectedToInclude.map(e => e.fss_id),
        order_id: this.updateOrderID,
        job_type: "add",
      }

      CarbonReadyAPI.postEAOrderFSS(payload)
        .then(response => {
          if (response.status == 200) {
            this.refreshFSSList()
            this.selectedToInclude = []
            //this.$emit("save-from-modal")
          } else {
            this.errorText = response.data
            this.showError = true
          }
        })
        .catch(err => {
          this.errorText = err.response.data
          this.showError = true
        })
    },

    refreshFSSList() {
      this.showLoading = true
      this.fieldSupplyEligible = []
      this.fieldSupply = []
      this.totalAcreageEligible = 0
      this.totalAcreageInProgram = 0
      CarbonReadyAPI.getEAOrderFSS({
        order_id: this.updateOrderID,
        restrict_corporation: 0,
      }).then(response => {
        this.getInsetRollup()

        this.fieldSupplyList = response.data

        let summaryDataDict = {}
        this.fieldSupplyList.forEach(fss => {
          if (summaryDataDict.hasOwnProperty(fss["channel_partner_name"])) {
            summaryDataDict[fss["channel_partner_name"]]["acreage"] +=
              fss["fieldcrop"]["acreage"]
            summaryDataDict[fss["channel_partner_name"]]["num_fields"] += 1
          } else {
            summaryDataDict[fss["channel_partner_name"]] = {
              channelPartner: fss["channel_partner_name"],
              acreage: fss["fieldcrop"]["acreage"],
              num_fields: 1,
            }
          }
          //let loi_name = enroll["loi_entity_name"]
          //enroll["fieldsupply"].forEach(fss => {
          let fss_dict = {
            field: fss["fieldcrop"]["field_name"],
            field_id: fss["fieldcrop"]["field_id"],
            client: fss["client"],
            crop: fss["fieldcrop"]["crop_name"],
            year: fss["fieldcrop"]["year"],
            fss_id: fss["id"],
            status: fss["status"],
            channel_partner: fss["channel_partner_name"],
            acreage: fss["fieldcrop"]["acreage"],
            average_co2e: fss["scope3_emission"],
            //loi_entity: loi_name,
            //enrollment_id: enroll["id"],
          }
          if (fss["matched_program"] == null) {
            this.fieldSupplyEligible.push(fss_dict)
            this.totalAcreageEligible += fss["fieldcrop"]["acreage"]
          } else if (fss["matched_program"] == this.updateOrderID) {
            this.fieldSupply.push(fss_dict)
            this.totalAcreageInProgram += fss["fieldcrop"]["acreage"]
          }
        })

        this.summaryDataArray = []
        for (let key in summaryDataDict) {
          let tempVal = summaryDataDict[key]
          tempVal["acreage"] = summaryDataDict[key]["acreage"].toFixed(0)
          this.summaryDataArray.push(tempVal)
        }
        this.showLoading = false
      })
    },

    getInsetRollup() {
      if (this.updateOrderID != null) {
        let params = { order_id: this.updateOrderID }

        CarbonReadyAPI.getInsetOrderRollup(params).then(response => {
          this.insetOrderRollupName = response.data.name
          this.insetOrderRollupCO2e = response.data.average_co2e
          this.insetOrderRollupAcreSummary =
            response.data.acres_allocated + " / " + response.data.acres_allotted
        })
      }
    },
  },

  mounted() {
    if (this.updateOrderID != null) {
      this.refreshFSSList()
      this.getInsetRollup()
    }
  },
}
</script>

<style scoped>
.pw-error {
  color: red;
  font-weight: 500;
}
</style>
