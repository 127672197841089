<template>
  <b-sidebar
    id="evidencing-group-editing-sidebar"
    width="700px"
    :title="editStep == 'initial' ? 'Edit Group' : 'Confirm Changes'"
    v-model="show"
    right
    shadow
    backdrop
  >
    <div class="sidebar-body" v-if="editStep == 'initial'">
      <v-btn-toggle
        v-model="editingBtnGroup"
        tile
        color="#000000"
        class="d-flex flex-wrap editing-buttons-toggle"
        group
        mandatory
      >
        <v-btn height="32px" value="metadata">Group Metadata</v-btn>
        <v-btn height="32px" value="fieldcrop">Fields + Crops</v-btn>
        <v-btn height="32px" value="evidencing">Evidencing</v-btn>
      </v-btn-toggle>

      <div class="sidebar-editing-section" v-if="editingBtnGroup == 'metadata'">
        <v-text-field
          outlined
          label="Group Name"
          placeholder="Group Name"
          v-model="groupMetadata['name']"
        />

        <v-textarea
          outlined
          label="Group Notes"
          placeholder="Group Notes"
          v-model="groupMetadata['notes']"
        />
      </div>

      <div
        class="sidebar-editing-section"
        v-else-if="editingBtnGroup == 'fieldcrop'"
      >
        <p>Add Fields to Evidencing Group</p>

        <div class="add-new-field-ids-wrapper">
          <v-text-field
            label="Field IDs (Comma Separated)"
            placeholder="Enter Field IDs (Comma Separated)"
            v-model="CSVFieldIds"
            outlined
          />

          <v-progress-circular
            v-if="loadingFieldsFetch"
            indeterminate
            :size="48"
            color="#79c61c"
          />
          <v-btn
            v-else
            :disabled="CSVFieldIds.length == 0"
            height="56px"
            outlined
            @click="fetchNewFieldCrops"
            >Submit</v-btn
          >
        </div>

        <div class="evidencing-fields-table">
          <v-simple-table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Crop</th>
                <th>Planting Dates</th>
                <th>Cancel</th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(fieldSpec, idx) in fieldsToAdd"
                :key="fieldSpec['id'] + '-' + idx + '-' + fieldSpec['name']"
              >
                <td>{{ fieldSpec["id"] }}</td>
                <td>{{ fieldSpec["name"] }}</td>
                <td>
                  {{
                    Object.values(fieldSpec["crops"])
                      .map(c => c["name"])
                      .join(", ")
                  }}
                </td>
                <td>
                  <p
                    v-if="fieldSpec['crops'] != null"
                    v-for="(cropSpec, cIdx) in fieldSpec['crops']"
                    :key="cropSpec + '-' + cIdx"
                  >
                    {{ cropSpec["plantings"].join(", ") }}
                  </p>
                  <p v-else>None</p>
                </td>
                <td>
                  <v-icon @click="removeFieldToBeAdded(fieldSpec['id'])"
                    >mdi-trash-can-outline</v-icon
                  >
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </div>

        <div class="evidencing-fields-table evidencing-existing-fields-table">
          <p>Remove Fields from Evidencing Group</p>
          <v-simple-table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Crops</th>
                <th>Planting Dates</th>
                <th>Remove?</th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(fieldSpec, idx) in getAllFieldCrops"
                :key="fieldSpec['id'] + '-' + fieldSpec['name'] + '-' + idx"
                :class="
                  existingFieldsToRemove.includes(
                    `${fieldSpec['id']}-${fieldSpec['cropId']}`
                  )
                    ? 'to-remove'
                    : ''
                "
              >
                <td>{{ fieldSpec["id"] }}</td>
                <td>{{ fieldSpec["name"] }}</td>
                <td>
                  {{
                    Object.values(fieldSpec["crops"])
                      .map(c => c["name"])
                      .join(", ")
                  }}
                </td>
                <td>
                  <p
                    v-if="fieldSpec['crops'] != null"
                    v-for="(cropSpec, cIdx) in fieldSpec['crops']"
                    :key="cropSpec + '-' + cIdx"
                  >
                    {{
                      cropSpec["plantings"]
                        ? cropSpec["plantings"].join(", ")
                        : null
                    }}
                  </p>
                  <p v-else>None</p>
                </td>
                <td class="checkbox-cell">
                  <v-checkbox
                    v-model="existingFieldsToRemove"
                    :value="fieldSpec['id']"
                  />
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </div>
      </div>

      <div
        class="sidebar-editing-section"
        v-else-if="editingBtnGroup == 'evidencing'"
      >
        <div class="existing-evidencing-list">
          <p>Evidencing in this group</p>

          <div
            class="practices-table"
            v-for="(evidencingSpec, eIdx) in Object.entries(
              allEvidencingTypesWithFields
            )"
            :key="evidencingSpec + '-' + eIdx"
          >
            <p>{{ getPracticeTitle(evidencingSpec[0]) }}</p>

            <v-simple-table>
              <thead>
                <tr>
                  <th>Field Id</th>
                  <th>Field Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(fieldId, idx) in allFieldIds"
                  :key="fieldId + '---' + idx"
                >
                  <td>{{ fieldId }}</td>
                  <td>{{ getFieldName(fieldId) }}</td>
                  <td class="checkbox-cell">
                    <v-checkbox
                      v-model="existingPracticesFieldsSpec[evidencingSpec[0]]"
                      :value="fieldId"
                    />
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </div>
        </div>
      </div>
    </div>

    <div class="sidebar-body edit-summary" v-else-if="editStep == 'confirm'">
      <p>Confirm your changes:</p>

      <div v-if="editSummary['metadata']['hasChanges']">
        <p>Metadata</p>
        <ul>
          <li
            v-if="
              editSummary['metadata']['name'] != activeEvidencingGroup['name']
            "
          >
            Changed Group Name
          </li>
          <li
            v-if="
              editSummary['metadata']['notes'] != activeEvidencingGroup['notes']
            "
          >
            Changed Group Notes
          </li>
        </ul>
      </div>

      <div v-if="editSummary['fieldcrop']['hasChanges']">
        <p>Field/Crops</p>
        <ul>
          <li
            v-for="(field, idx) in editSummary['fieldcrop']['add']"
            :key="field['name'] + '-_-' + idx"
          >
            Add {{ field["name"] }} (id: {{ field["id"] }}) to group
          </li>
          <li
            v-for="(field, idx) in editSummary['fieldcrop']['remove']"
            :key="field['name'] + '-__-' + idx"
          >
            Remove {{ field["name"] }} (id: {{ field["id"] }}) from group
          </li>
        </ul>
      </div>

      <div v-if="editSummary['evidencing']['hasChanges']">
        <p>Evidencing</p>
        <ul>
          <li
            v-for="([practice, fields], idx) in editSummary['evidencing'][
              'add'
            ]"
            :key="practice + '-___' + idx"
          >
            Add {{ getPracticeTitle(practice) }} with fields:
            <ul>
              <li v-for="fieldId in fields" :key="fieldId + '-_=-' + idx">
                {{ getFieldName(fieldId) }} (id: {{ fieldId }})
              </li>
            </ul>
          </li>
          <li
            v-for="([practice, fields], idx) in editSummary['evidencing'][
              'remove'
            ]"
            :key="practice + '-__-_' + idx"
          >
            Remove {{ getPracticeTitle(practice) }} with fields:
            <ul>
              <li v-for="fieldId in fields" :key="fieldId + '-__=-' + idx">
                {{ getFieldName(fieldId) }} (id: {{ fieldId }})
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>

    <div class="sidebar-footer">
      <v-btn @click="cancelChanges" outlined height="48px">Cancel</v-btn>
      <v-btn
        v-if="editStep == 'initial'"
        @click="moveToConfirm"
        :disabled="!isSaveable"
        outlined
        height="48px"
        >Save</v-btn
      >
      <v-btn
        v-if="editStep == 'confirm'"
        @click="applyChanges"
        outlined
        height="48px"
        >Confirm</v-btn
      >
    </div>
  </b-sidebar>
</template>

<script>
import Vue from "vue"
import { mapGetters, mapState } from "vuex"
import { Evidencing } from "@/store/modules"
import { EVIDENCING_TYPE_CHOICES } from "@/constants/defaults"
import { fetchFieldCrops } from "@/api/EvidencingAPI"

export default {
  name: "EvidencingGroupEditingSidebar",
  emits: ["closeSidebar"],
  components: {},
  props: {
    visible: { type: Boolean, required: true },
  },
  data() {
    return {
      editStep: "initial",
      cancelled: true,
      show: false,
      editingBtnGroup: "metadata",
      loadingFieldsFetch: false,
      fieldsFetchError: "",
      groupMetadata: {
        name: "",
        notes: "",
      },
      CSVFieldIds: "",
      existingFieldsToRemove: [],
      fieldsToAdd: [],
      existingPracticesFieldsSpec: {},
    }
  },
  computed: {
    ...mapGetters({
      allFields: Evidencing.Getters.allFields,
      allEvidencingTypesWithFields:
        Evidencing.Getters.allEvidencingTypesWithFields,
      allFieldIds: Evidencing.Getters.allFieldIds,
      getAllFieldCrops: Evidencing.Getters.getAllFieldCrops,
    }),
    ...mapState({
      activeEvidencingGroup: state => state.Evidencing.activeEvidencingGroup,
      year: state => state.Organization.year,
    }),
    evidencingHasBeenModified() {
      return Object.values(this.modifiedEvidencing).some(
        e => e["add"].length > 0 || e["remove"].length > 0
      )
    },
    modifiedEvidencing() {
      const modifiedEvidencing = {}

      for (const evidencingType in this.existingPracticesFieldsSpec) {
        if (!(evidencingType in modifiedEvidencing))
          modifiedEvidencing[evidencingType] = { add: [], remove: [] }

        for (const fieldId of this.allEvidencingTypesWithFields[
          evidencingType
        ]) {
          // if this doesn't exist in the other tracker, it's set to be removed
          if (
            !this.existingPracticesFieldsSpec[evidencingType].includes(fieldId)
          ) {
            modifiedEvidencing[evidencingType]["remove"].push(fieldId)
          }
        }

        for (const fieldId of this.existingPracticesFieldsSpec[
          evidencingType
        ]) {
          // if this doesn't exist in the main getter, it's set to be added
          if (
            !this.allEvidencingTypesWithFields[evidencingType].includes(fieldId)
          ) {
            modifiedEvidencing[evidencingType]["add"].push(fieldId)
          }
        }
      }

      return modifiedEvidencing
    },
    isSaveable() {
      if (this.activeEvidencingGroup != null) {
        // metadata changes
        if (this.groupMetadata["name"] != this.activeEvidencingGroup["name"])
          return true
        if (this.groupMetadata["notes"] != this.activeEvidencingGroup["notes"])
          return true

        // fieldcrop changes
        if (this.existingFieldsToRemove.length > 0) return true
        if (this.fieldsToAdd.length > 0) return true

        // evidencing changes
        if (this.evidencingHasBeenModified) return true
      }

      return false
    },
    editSummary() {
      const summary = {
        metadata: {
          name: this.groupMetadata["name"],
          notes: this.groupMetadata["notes"],
          hasChanges:
            this.groupMetadata["name"] != this.activeEvidencingGroup["name"] ||
            this.groupMetadata["notes"] != this.activeEvidencingGroup["notes"],
        },
        fieldcrop: {
          add: this.fieldsToAdd,
          remove: this.existingFieldsToRemove.map(id =>
            this.allFields.find(f => f["id"] == id)
          ),
          hasChanges:
            this.existingFieldsToRemove.map(id =>
              this.allFields.find(f => f["id"] == id)
            ) || this.fieldsToAdd.length > 0,
        },
        evidencing: {
          add: [],
          remove: [],
          hasChanges: this.evidencingHasBeenModified,
        },
      }

      for (const evidencingType in this.modifiedEvidencing) {
        if (this.modifiedEvidencing[evidencingType]["add"].length > 0) {
          summary["evidencing"]["add"].push([
            evidencingType,
            this.modifiedEvidencing[evidencingType]["add"],
          ])
        }

        if (this.modifiedEvidencing[evidencingType]["remove"].length > 0) {
          summary["evidencing"]["remove"].push([
            evidencingType,
            this.modifiedEvidencing[evidencingType]["remove"],
          ])
        }
      }

      return summary
    },
  },
  methods: {
    getAppliedPractices(evidencingType) {},
    getFieldName(fieldId) {
      return this.allFields.find(({ id }) => fieldId == id)["name"]
    },
    getPracticeTitle(practice) {
      const found = EVIDENCING_TYPE_CHOICES.find(
        ({ value }) => value == practice
      )
      if (found != null) return found["name"]
      return "Not Found"
    },
    removeFieldToBeAdded(fieldId) {
      this.fieldsToAdd = this.fieldsToAdd.filter(({ id }) => id != fieldId)
    },
    fetchNewFieldCrops() {
      const fieldIds = [
        ...new Set(
          this.CSVFieldIds.trim()
            .split(",")
            .filter(
              id =>
                !this.allFieldIds.includes(id) ||
                this.fieldsToAdd.find(f => f["id"] == id) == null
            )
        ),
      ]

      this.loadingFieldsFetch = true
      this.fieldsFetchError = ""
      fetchFieldCrops({ fieldIds, year: this.year })
        .then(({ data }) => {
          for (const fieldId in data) {
            const foundFieldId = this.fieldsToAdd.find(
              ({ id }) => id == fieldId
            )
            if (foundFieldId != null) continue

            const fieldSpec = {
              id: fieldId,
              name: data[fieldId]["name"],
              crops: data[fieldId]["crops"],
            }

            this.fieldsToAdd.push(fieldSpec)
          }

          this.loadingFieldsFetch = false
        })
        .catch(err => {
          console.log("error!", err)
          this.loadingFieldsFetch = false
          this.fieldsFetchError = err
        })
    },
    cancelChanges() {
      this.cancelled = true
      this.show = false
    },
    moveToConfirm() {
      this.editStep = "confirm"
    },
    applyChanges() {
      this.cancelled = false
      this.show = false
    },
    removeSidebar() {
      this.$emit("closeSidebar", this.cancelled, this.editSummary)
      this.editStep = "initial"
      this.editingBtnGroup = "metadata"
      this.loadingFieldsFetch = false
      this.groupMetadata = { name: "", notes: "" }
      this.CSVFieldIds = ""
      this.existingFieldsToRemove = []
      this.fieldsToAdd = []
      this.existingPracticesFieldsSpec = {}
    },
  },
  watch: {
    visible(curr) {
      this.show = curr
    },
    show(curr) {
      if (!curr) this.removeSidebar(true)
      else {
        // map the vuex state to the sidebar-specific data properties
        Vue.set(this, "groupMetadata", {
          name: this.activeEvidencingGroup["name"],
          notes: this.activeEvidencingGroup["notes"],
        })

        Vue.set(this, "existingPracticesFieldsSpec", {
          ...this.allEvidencingTypesWithFields,
        })
      }
    },
  },
}
</script>

<style scoped>
::v-deep(#evidencing-group-editing-sidebar) {
  background: #ffffff !important;
  z-index: 99;
}

p {
  color: #000000;
}

::v-deep #evidencing-group-editing-sidebar header {
  margin-top: 64px;
  padding: 24px 36px;
}

::v-deep #evidencing-group-editing-sidebar header .close {
  margin-right: 16px !important;
}

::v-deep #evidencing-group-editing-sidebar header strong {
  margin-right: auto;
  text-transform: capitalize;
}

.sidebar-body {
  padding: 0 36px 36px;
  position: relative;
  margin-bottom: 96px;
}
.sidebar-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 72px;
  background: #ffffff;
  border-top: 1px solid rgba(0, 0, 0, 0.25);
  padding: 0 36px;
  display: flex;
  align-items: center;
  z-index: 9;
}
.sidebar-footer > button {
  float: left;
  margin-right: 16px;
}
.editing-buttons-toggle > button {
  text-transform: none;
  padding: 6px 16px;
  letter-spacing: normal;
  border-radius: 4px !important;
}

.editing-buttons-toggle
  > button.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
  background: none !important;
}

.editing-buttons-toggle > button:first-of-type {
  margin-left: 0;
}

.sidebar-editing-section {
  margin-top: 16px;
  border-radius: 6px;
}

.evidencing-fields-table {
  max-height: 400px;
  overflow-y: scroll;
  padding: 16px;
  background: #f7f8fa;
  border-radius: 6px;
}
.evidencing-fields-table th {
  position: sticky;
  top: -16px;
  background: #ffffff;
  z-index: 9;
}
.evidencing-existing-fields-table {
  height: 400px;
  margin-top: 24px;
}
.evidencing-existing-fields-table > p {
  margin-bottom: 8px;
}
tr.to-remove > *:not(.checkbox-cell) {
  text-decoration: line-through;
}
td p {
  margin: 0;
}
::v-deep(.v-ripple__container),
::v-deep(.v-input--selection-controls__ripple),
.checkbox-cell ::v-deep(.v-messages),
::v-deep(.v-text-field__details) {
  display: none !important;
}
.checkbox-cell .v-input,
.checkbox-cell .v-input ::v-deep(.v-input__slot) {
  margin: 0;
  padding: 0;
}
.add-new-field-ids-wrapper {
  display: flex;
  gap: 16px;
}
.existing-evidencing-list {
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
}
.existing-evidencing-list > p {
  width: 100%;
  margin: 0 0 8px;
}
.practices-table {
  position: relative;
  margin-top: 16px;
  width: 100%;
}
.practices-table > p {
  position: sticky;
  top: 0;
  padding: 8px 0;
  background: #ffffff;
  font-size: 16px;
  line-height: 1;
  font-weight: bold;
  margin: 0 0 4px;
}
.v-expansion-panel-header p {
  margin: 0;
}
.v-expansion-panel-header p:first-of-type {
  width: 100%;
}
.v-expansion-panel-header p:last-of-type {
  width: 48px;
  text-align: center;
}
.new-additions-evidencing-list {
  margin-top: 48px;
}
.new-additions-evidencing-list > p {
  font-size: 20px;
  line-height: 1;
  margin: 0 0 8px;
}
.edit-summary > div {
  margin-top: 16px;
}
.edit-summary > div > p {
  margin-bottom: 8px;
}
</style>
